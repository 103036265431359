@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@-webkit-keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  10% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg); }
  40% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); } }

@keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  10% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg); }
  40% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); } }

.pro-sidebar {
  background:#1d1d1d;
  color: #adadad;
  height: 100%;
  width: 260px;
  min-width: 260px;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 111; }
  .pro-sidebar > img.sidebar-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    opacity: 0.3;
    left: 0;
    top: 0; }
  .pro-sidebar > .pro-sidebar-inner {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 110; }
    .pro-sidebar > .pro-sidebar-inner .pro-sidebar-header {
      height: 40px;
      line-height: 40px;
     }
    .pro-sidebar > .pro-sidebar-inner .pro-sidebar-content {
      flex-grow: 1; }
    .pro-sidebar > .pro-sidebar-inner .pro-sidebar-footer {
      flex: 1 1;
      display: flex;
      justify-content:center;
      align-items:center;
    height: 90px;
    line-height: 90px;
      border-top: 1px solid rgba(173, 173, 173, 0.2); }
    .pro-sidebar > .pro-sidebar-inner ul {
      list-style-type: none;
      padding: 0;
      margin: 0; }
  .pro-sidebar .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 109;
    display: none; }
  .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px; }
  .pro-sidebar.rtl {
    text-align: right;
    direction: rtl; }
  @media (max-width: 480px) {
    .pro-sidebar.xs {
      position: fixed;
      left: -270px; }
      .pro-sidebar.xs.collapsed {
        left: -80px; }
      .pro-sidebar.xs.toggled {
        left: 0; }
        .pro-sidebar.xs.toggled .overlay {
          display: block; }
      .pro-sidebar.xs.rtl {
        left: auto;
        right: -270px; }
        .pro-sidebar.xs.rtl.collapsed {
          left: auto;
          right: -80px; }
        .pro-sidebar.xs.rtl.toggled {
          left: auto;
          right: 0; } }
  @media (max-width: 576px) {
    .pro-sidebar.sm {
      position: fixed;
      left: -270px; }
      .pro-sidebar.sm.collapsed {
        left: -80px; }
      .pro-sidebar.sm.toggled {
        left: 0; }
        .pro-sidebar.sm.toggled .overlay {
          display: block; }
      .pro-sidebar.sm.rtl {
        left: auto;
        right: -270px; }
        .pro-sidebar.sm.rtl.collapsed {
          left: auto;
          right: -80px; }
        .pro-sidebar.sm.rtl.toggled {
          left: auto;
          right: 0; } }
  @media (max-width: 768px) {
    .pro-sidebar.md {
      position: fixed;
      left: -270px; }
      .pro-sidebar.md.collapsed {
        left: -80px; }
      .pro-sidebar.md.toggled {
        left: 0; }
        .pro-sidebar.md.toggled .overlay {
          display: block; }
      .pro-sidebar.md.rtl {
        left: auto;
        right: -270px; }
        .pro-sidebar.md.rtl.collapsed {
          left: auto;
          right: -80px; }
        .pro-sidebar.md.rtl.toggled {
          left: auto;
          right: 0; } }
  @media (max-width: 992px) {
    .pro-sidebar.lg {
      position: fixed;
      left: -270px; }
      .pro-sidebar.lg.collapsed {
        left: -80px; }
      .pro-sidebar.lg.toggled {
        left: 0; }
        .pro-sidebar.lg.toggled .overlay {
          display: block; }
      .pro-sidebar.lg.rtl {
        left: auto;
        right: -270px; }
        .pro-sidebar.lg.rtl.collapsed {
          left: auto;
          right: -80px; }
        .pro-sidebar.lg.rtl.toggled {
          left: auto;
          right: 0; } }
  @media (max-width: 1200px) {
    .pro-sidebar.xl {
      position: fixed;
      left: -270px; }
      .pro-sidebar.xl.collapsed {
        left: -80px; }
      .pro-sidebar.xl.toggled {
        left: 0; }
        .pro-sidebar.xl.toggled .overlay {
          display: block; }
      .pro-sidebar.xl.rtl {
        left: auto;
        right: -270px; }
        .pro-sidebar.xl.rtl.collapsed {
          left: auto;
          right: -80px; }
        .pro-sidebar.xl.rtl.toggled {
          left: auto;
          right: 0; } }

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  content: '';
  display: inline-block;
  width: 4px;
  min-width: 4px;
  height: 4px;
  border: 1px solid #2b2b2b;
  border-radius: 50%;
  margin-right: 15px;
  position: relative;
  box-shadow: 1px 0px 0px #adadad, 0px -1px 0px #adadad, 0px 1px 0px #adadad, -1px 0px 0px #adadad; }

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.pro-sidebar .pro-menu {
  padding-top: 10px;
  padding-bottom: 10px; }
  .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    position: relative;
    background-color: #2b2b2b; }
    .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
      padding-top: 15px;
      padding-bottom: 15px; }
  .pro-sidebar .pro-menu a {
    text-decoration: none;
    color: #adadad; }
    .pro-sidebar .pro-menu a:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: transparent; }
    .pro-sidebar .pro-menu a:hover {
      color: #d8d8d8; }
  .pro-sidebar .pro-menu .pro-menu-item {
    font-size: 15px; }
    .pro-sidebar .pro-menu .pro-menu-item.active {
      color: #d8d8d8; }
    .pro-sidebar .pro-menu .pro-menu-item .suffix-wrapper {
      opacity: 1;
      transition: opacity 0.2s; }
    .pro-sidebar .pro-menu .pro-menu-item .prefix-wrapper {
      display: flex;
      margin-right: 5px;
      opacity: 1;
      transition: opacity 0.2s; }
    .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 8px 15px 8px 20px;
      cursor: pointer; }
      .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
        outline: none;
        color: #d8d8d8; }
      .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
        margin-right: 10px;
        font-size: 14px;
        width: 35px;
        min-width: 35px;
        
        height: 35px;
        line-height: 35px;
        text-align: center;
        display: inline-block; }
        .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper .pro-icon {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center; }
      .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
        flex-grow: 1;
        flex-shrink: 1; }
      .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
        color: #d8d8d8; }
        .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover .pro-icon-wrapper .pro-icon {
          -webkit-animation: swing ease-in-out 0.5s 1 alternate;
                  animation: swing ease-in-out 0.5s 1 alternate;
         }
    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item:before {
      background: #adadad; }
    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
      position: absolute;
      right: 20px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
      .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
        display: inline-block;
        border-style: solid;
        border-color: #adadad;
        border-width: 0 2px 2px 0;
        padding: 2.5px;
        vertical-align: middle;
        transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
        transition: transform 0.3s, -webkit-transform 0.3s;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item:before {
      background: transparent !important; }
    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
      padding-left: 20px; }
      .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
        padding: 8px 30px 8px 15px; }
        .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item > .pro-icon-wrapper {
           display: inline-block !important; }
        .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item .pro-arrow-wrapper {
          display: none; }
  .pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background-color: #2b2b2b; }
  .pro-sidebar .pro-menu.square .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    border-radius: 0; }
  .pro-sidebar .pro-menu.round .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    border-radius: 4px; }
  .pro-sidebar .pro-menu.circle .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    border-radius: 50%; }

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item {
  position: relative; }
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-item > .suffix-wrapper,
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-item > .prefix-wrapper {
    opacity: 0; }
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-list-item {
    background-color: #2b2b2b;
    z-index: 111; }
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer; }
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu {
    position: relative; }
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-item {
      pointer-events: none; }
      .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
        display: none; }
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item {
      height: auto !important;
      position: fixed;
      visibility: hidden;
      min-width: 220px;
      max-width: 270px;
      background-color: transparent;
      max-height: 100%;
      padding-left: 3px; }
      .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item.has-arrow {
        padding-left: 10px; }
      .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
        max-height: 100vh;
        overflow-y: auto;
        background-color: #2b2b2b;
        padding-left: 20px;
        border-radius: 4px; }
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover > .pro-inner-list-item {
      transition: visibility, -webkit-transform 0.3s;
      transition: visibility, transform 0.3s;
      transition: visibility, transform 0.3s, -webkit-transform 0.3s;
      visibility: visible; }
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover .pro-icon-wrapper .pro-icon {
      -webkit-animation: swing ease-in-out 0.5s 1 alternate;
              animation: swing ease-in-out 0.5s 1 alternate; }
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
      padding: 8px 30px 8px 5px; }

.pro-sidebar.rtl .pro-menu .pro-menu-item .prefix-wrapper {
  margin-right: 0;
  margin-left: 5px; }

.pro-sidebar.rtl .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 8px 20px 8px 35px; }
  .pro-sidebar.rtl .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    margin-right: 0;
    margin-left: 10px; }

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
  right: auto;
  left: 20px; }
  .pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0;
  padding-right: 20px; }
  .pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 15px 8px 30px; }
    .pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
      margin-right: 0;
      margin-left: 15px; }

.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item {
  padding-left: 0;
  padding-right: 3px; }
  .pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item.has-arrow {
    padding-right: 10px; }
  .pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
    padding-left: 0;
    padding-right: 20px; }

.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 5px 8px 30px; }

.popper-arrow {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent; }

.popper-element[data-popper-placement^='left'] > .popper-arrow {
  right: 0;
  border-right: 7px solid #2b2b2b; }

.popper-element[data-popper-placement^='right'] > .popper-arrow {
  left: 0;
  border-left: 7px solid #2b2b2b; }

.react-slidedown {
  height: 0;
  transition-property: none;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out; }

.react-slidedown.transitioning {
  overflow-y: hidden; }

.react-slidedown.closed {
  display: none; }

@font-face {
  font-family: 'fontello';
  src: url(/static/media/fontello.11cf251b.eot);
  src: url(/static/media/fontello.11cf251b.eot#iefix) format('embedded-opentype'),
       url(/static/media/fontello.d2486e2b.woff2) format('woff2'),
       url(/static/media/fontello.03282d86.woff) format('woff'),
       url(/static/media/fontello.5555667f.ttf) format('truetype'),
       url(/static/media/fontello.31af5543.svg#fontello) format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?57125366#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-atendimentoaberto1:before { content: '\e800'; } /* '' */
.icon-add:before { content: '\e801'; } /* '' */
.icon-dashboardoff:before { content: '\e802'; } /* '' */
.icon-pause:before { content: '\e803'; } /* '' */
.icon-senha:before { content: '\e804'; } /* '' */
.icon-user:before { content: '\e805'; } /* '' */
.icon-feito:before { content: '\e806'; } /* '' */
.icon-empresa:before { content: '\e807'; } /* '' */
.icon-atendimentoss:before { content: '\e808'; } /* '' */
.icon-menu2:before { content: '\e809'; } /* '' */
.icon-facebook-logo:before { content: '\e80a'; } /* '' */
.icon-vip:before { content: '\e80b'; } /* '' */
.icon-setavoltar:before { content: '\e80c'; } /* '' */
.icon-email:before { content: '\e80d'; } /* '' */
.icon-agendado:before { content: '\e80e'; } /* '' */
.icon-clerk:before { content: '\e80f'; } /* '' */
.icon-loading:before { content: '\e810'; } /* '' */
.icon-all:before { content: '\e811'; } /* '' */
.icon-d:before { content: '\e812'; } /* '' */
.icon-microphone:before { content: '\e813'; } /* '' */
.icon-down-arrow:before { content: '\e814'; } /* '' */
.icon-clip:before { content: '\e815'; } /* '' */
.icon-send:before { content: '\e816'; } /* '' */
.icon-new-user:before { content: '\e817'; } /* '' */
.icon-new-user3:before { content: '\e818'; } /* '' */
.icon-new-user2:before { content: '\e819'; } /* '' */
.icon-menu3:before { content: '\e81a'; } /* '' */
.icon-loupe:before { content: '\e81b'; } /* '' */
.icon-search:before { content: '\e81c'; } /* '' */
.icon-house:before { content: '\e81d'; } /* '' */
.icon-man-user:before { content: '\e81e'; } /* '' */
.icon-new-setor2:before { content: '\e81f'; } /* '' */
.icon-new-setor1:before { content: '\e820'; } /* '' */
.icon-multiple-users-silhouette:before { content: '\e821'; } /* '' */
.icon-warning:before { content: '\e822'; } /* '' */
.icon-favorite-heart-button:before { content: '\e823'; } /* '' */
.icon-icon:before { content: '\e824'; } /* '' */
.icon-block:before { content: '\e825'; } /* '' */
.icon-iconwpp:before { content: '\e826'; } /* '' */
.icon-settings:before { content: '\e830'; } /* '' */
.icon-settings-gears:before { content: '\e831'; } /* '' */
.icon-share:before { content: '\e832'; } /* '' */
.icon-instagram-logo:before { content: '\e833'; } /* '' */
.icon-envelope:before { content: '\e834'; } /* '' */
.icon-sair:before { content: '\e835'; } /* '' */
.icon-configuracao:before { content: '\e836'; } /* '' */
.icon-sincronizar:before { content: '\e837'; } /* '' */
.icon-configuracao2:before { content: '\e838'; } /* '' */
.icon-headset:before { content: '\e839'; } /* '' */
.icon-atendimentos:before { content: '\e83a'; } /* '' */
.icon-logout:before { content: '\e83b'; } /* '' */
.icon-close3:before { content: '\e83c'; } /* '' */
.icon-close:before { content: '\e83d'; } /* '' */
.icon-add2:before { content: '\e83e'; } /* '' */
.icon-cancel:before { content: '\e83f'; } /* '' */
.icon-comvisao:before { content: '\e840'; } /* '' */
.icon-semvisao:before { content: '\e841'; } /* '' */
.icon-box:before { content: '\e842'; } /* '' */
.icon-seminternet:before { content: '\e843'; } /* '' */
.icon-editable-speech-bubble:before { content: '\e844'; } /* '' */
.icon-comment:before { content: '\e845'; } /* '' */
.icon-comment2:before { content: '\e846'; } /* '' */
.icon-edit:before { content: '\e847'; } /* '' */
.icon-tempoespera:before { content: '\e848'; } /* '' */
.icon-atender:before { content: '\e849'; } /* '' */
.icon-vencimento:before { content: '\e84a'; } /* '' */
.icon-prioridade2:before { content: '\e84b'; } /* '' */
.icon-tempoespera2:before { content: '\e84c'; } /* '' */
.icon-angle-arrow-down:before { content: '\e84d'; } /* '' */
.icon-check:before { content: '\e84e'; } /* '' */
.icon-download:before { content: '\e84f'; } /* '' */
.icon-editar:before { content: '\e850'; } /* '' */
.icon-clip2:before { content: '\e851'; } /* '' */
.icon-calendar:before { content: '\e852'; } /* '' */
.icon-notification:before { content: '\e853'; } /* '' */
.icon-interrogacao:before { content: '\e854'; } /* '' */
.icon-chamadoatendimento:before { content: '\e855'; } /* '' */
.icon-chamadocancelado:before { content: '\e856'; } /* '' */
.icon-chamadoaberto:before { content: '\e857'; } /* '' */
.icon-chamadofechado2:before { content: '\e858'; } /* '' */
.icon-chamadoematendimento2:before { content: '\e859'; } /* '' */
.icon-atendimentotodos:before { content: '\e85a'; } /* '' */
.icon-copy:before { content: '\e85b'; } /* '' */
.icon-compartilhar:before { content: '\e85c'; } /* '' */
.icon-numbers:before { content: '\e85d'; } /* '' */
.icon-addaviso:before { content: '\e85e'; } /* '' */
.icon-megaphone:before { content: '\e85f'; } /* '' */
.icon-pesquisa:before { content: '\e860'; } /* '' */
.icon-magnifier:before { content: '\e861'; } /* '' */
.icon-review:before { content: '\e862'; } /* '' */
.icon-next:before { content: '\e863'; } /* '' */
.icon-in-love:before { content: '\e864'; } /* '' */
.icon-good-mood-emoticon:before { content: '\e865'; } /* '' */
.icon-sceptic:before { content: '\e866'; } /* '' */
.icon-smiling-circular-face:before { content: '\e867'; } /* '' */
.icon-big-smiley-face:before { content: '\e868'; } /* '' */
.icon-sad:before { content: '\e869'; } /* '' */
.icon-smiley:before { content: '\e86a'; } /* '' */
.icon-bar-chart:before { content: '\e86b'; } /* '' */
.icon-bar-chart2:before { content: '\e86c'; } /* '' */
.icon-danger1:before { content: '\e86d'; } /* '' */
.icon-danger:before { content: '\e86e'; } /* '' */
.icon-photo-camera1:before { content: '\e86f'; } /* '' */
.icon-photo-camera2:before { content: '\e870'; } /* '' */
.icon-photo-camera:before { content: '\e871'; } /* '' */
.icon-question-mark-on-a-circular-black-background:before { content: '\e872'; } /* '' */
.icon-search-1:before { content: '\e877'; } /* '' */
.icon-house-search:before { content: '\e878'; } /* '' */
.icon-searching-for-a-house:before { content: '\e879'; } /* '' */
.icon-house-searcher:before { content: '\e87a'; } /* '' */
.icon-star:before { content: '\e87d'; } /* '' */
.icon-starnotavalied:before { content: '\e87e'; } /* '' */
.icon-booking:before { content: '\e881'; } /* '' */
.icon-hotel:before { content: '\e882'; } /* '' */
.icon-hotel1:before { content: '\e883'; } /* '' */
.icon-funnel:before { content: '\e884'; } /* '' */
.icon-excel:before { content: '\e885'; } /* '' */
.icon-spreadsheet:before { content: '\e886'; } /* '' */
.icon-pdf1:before { content: '\e887'; } /* '' */
.icon-pdf2:before { content: '\e888'; } /* '' */
.icon-takeoff-the-plane:before { content: '\e88a'; } /* '' */
.icon-trocarplano:before { content: '\e88b'; } /* '' */
.icon-localization:before { content: '\e88c'; } /* '' */
.icon-nofilter:before { content: '\e88d'; } /* '' */
.icon-up-arrow:before { content: '\e88e'; } /* '' */
.icon-phone:before { content: '\e88f'; } /* '' */
.icon-whatsapp:before { content: '\e890'; } /* '' */
.icon-open-menu:before { content: '\e891'; } /* '' */
.icon-menunew:before { content: '\e892'; } /* '' */
.icon-menunew1:before { content: '\e893'; } /* '' */
.icon-open-menu1:before { content: '\e899'; } /* '' */
.icon-add1:before { content: '\e89a'; } /* '' */
.icon-add2-1:before { content: '\e89b'; } /* '' */
.icon-add4:before { content: '\e89c'; } /* '' */
.icon-add3:before { content: '\e89d'; } /* '' */
.icon-feitoweb:before { content: '\e89e'; } /* '' */
.icon-esperawev:before { content: '\e89f'; } /* '' */
.icon-prioridadeweb:before { content: '\e8a0'; } /* '' */
.icon-prioridadeweb2:before { content: '\e8a1'; } /* '' */
.icon-prioridadeweb3:before { content: '\e8a2'; } /* '' */
.icon-upload:before { content: '\e8a5'; } /* '' */
.icon-check-1:before { content: '\e8a6'; } /* '' */
.icon-pause-1:before { content: '\e8a7'; } /* '' */
.icon-play:before { content: '\e8a8'; } /* '' */
.icon-play-button-arrowhead:before { content: '\e8a9'; } /* '' */
.icon-stop-button-black-rounded-square:before { content: '\e8aa'; } /* '' */
.icon-stop-button:before { content: '\e8ab'; } /* '' */
.icon-send3:before { content: '\e8b5'; } /* '' */
.icon-send2:before { content: '\e8b6'; } /* '' */
.icon-logoutatendimento:before { content: '\e8b7'; } /* '' */
.icon-refresh2:before { content: '\e8b8'; } /* '' */
.icon-refresh-arrow:before { content: '\e8b9'; } /* '' */
.icon-calendar-(3):before { content: '\e8ba'; } /* '' */
.icon-computerlicense:before { content: '\e8bb'; } /* '' */
.icon-nocomputerlicense:before { content: '\e8bc'; } /* '' */
.icon-nocomputer:before { content: '\e8bd'; } /* '' */
.icon-no-chatting:before { content: '\e8be'; } /* '' */
.icon-chat1:before { content: '\e8bf'; } /* '' */
.icon-transferir:before { content: '\e8c0'; } /* '' */
.icon-audio-speaker-off:before { content: '\e8c1'; } /* '' */
.icon-audio-speaker-on:before { content: '\e8c2'; } /* '' */
.icon-listing-option:before { content: '\e8c3'; } /* '' */
.icon-list-on-window:before { content: '\e8c4'; } /* '' */
.icon-portal:before { content: '\e8d3'; } /* '' */
.icon-web-site:before { content: '\e8d4'; } /* '' */
.icon-rubbish-bin:before { content: '\e8f0'; } /* '' */
.icon-training:before { content: '\e8f6'; } /* '' */
.icon-report:before { content: '\e8f7'; } /* '' */
.icon-setup:before { content: '\e8f8'; } /* '' */
.icon-nextbold:before { content: '\e8fa'; } /* '' */
.icon-take-off:before { content: '\e8fc'; } /* '' */
.icon-help:before { content: '\e8fd'; } /* '' */
.icon-lifesaver:before { content: '\e8fe'; } /* '' */
.icon-sino:before { content: '\e904'; } /* '' */
.icon-smartphone:before { content: '\e928'; } /* '' */
.icon-no-mobile-phones-allowed:before { content: '\e929'; } /* '' */
.icon-no-phone4:before { content: '\e92a'; } /* '' */
.icon-global:before { content: '\e92b'; } /* '' */
.icon-phone123:before { content: '\e92c'; } /* '' */
.icon-icon_02:before { content: '\e96a'; } /* '' */
.icon-imagewpp:before { content: '\e9e3'; } /* '' */
.icon-video-camera:before { content: '\e9e4'; } /* '' */
.icon-documentwpp:before { content: '\e9e5'; } /* '' */
.icon-checkwpp:before { content: '\e9e6'; } /* '' */
.icon-double-tick-indicator:before { content: '\e9e7'; } /* '' */
.icon-checkwpp2:before { content: '\e9e8'; } /* '' */
.icon-clockteste3:before { content: '\e9e9'; } /* '' */
.icon-watch:before { content: '\e9eb'; } /* '' */
.icon-clockteste:before { content: '\e9ec'; } /* '' */

.App {
  font-family: sans-serif;
  text-align: center;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: transparent;
}


/**
 * Main wrapper
 */
.select-search {
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
 
}


.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
 
}

/**
 * Value wrapper
 */
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
  color:#FFF;
 
}

/**
 * Input
 */
.select-search__input {
  display: block;
  width: 100%;
  padding: 0 16px;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border:0;

  background: transparent;
  outline: none;
  font-family: "Noto Sans", sans-serif;
height: 30px;
  font-size: 12px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 30px;
  -webkit-appearance: none;
  
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: auto;
  cursor: initial;
  overflow: auto;
}

/**
 * Options wrapper
 */
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}
/* .themedComponent {
  --bg: #00ff00;
  padding: 2em;
  margin: 1em;
  background: var(--bg);
} */
/**
 * Options
 */
.select-search__options {
  list-style: none;

}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;  
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2fcc8b;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.3);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #CCC;
  padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: auto;
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

/* add css module styles here (optional) */
._1ceqH,
._1ceqH * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "Lato", sans-serif
}

._1ceqH ._2fG9h {
    width: 100%;
    margin: 0 auto;
    padding:  0;
}

._1ceqH ._dt3-T {
    min-height: 330px;
    background: #212121;
    border-radius: 0 0 3px 3px;
    display: flex;
    flex-direction: column;
}

._1ceqH ._1dpop {
    width: 60px;
    display: flex;
    height: 60px;
    position: fixed;
    justify-content: center;
    align-items: center;
    transition: .5s ease all;
    background: rgb(245, 0, 87);
    border-radius: 50%;
    bottom: 65px;
    right: 20%;
    color: #fff;
    font-size: 25px;
}

._1ceqH ._3neb0 {
    width: 25px;
    height: 25px;
    display: flex;
}

._1ceqH ._1dpop:hover {
    background-color: #fff;
}

._1ceqH ._3wi1g {
    fill: #fff;
    width: 100%;
    height: 100%;
}

._1ceqH ._1dpop:hover ._3wi1g {
    fill: rgb(245, 0, 87);
}

._1ceqH ._1lB9c {
    display: flex;
    justify-content: space-between;
    background: #bd9f61;
    align-items: center;
    padding: 20px 20px;
    color: #fff;
    border-radius: 3px 3px 0 0;
}

._1ceqH ._1lB9c ._2N9dq {
    font-weight: 400;
}

._1ceqH ._1lB9c ._3-aC9 {
    font-size: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease all;
}

._1ceqH ._1lB9c ._3-aC9:hover {
    background: rgba(123, 118, 106, 0.21);
}

._1ceqH ._3bC73 {
    position: relative;
    flex: 1 1;
}

._1ceqH ._3bC73 ._1dpop {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    bottom: 20px;
}

._1ceqH ._3bC73 ._1YOWG {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    bottom: 100px;
}

._1ceqH ._1Yplu {
    margin: 20px 30px;
}

._1ceqH ._1Yplu ._1Pz2d {
    border: 0;
    outline: 0;
    padding: 10px 20px;
    border-radius: 20px;
    background: #185fec;
    color: #fff;
    cursor: pointer;
    border: 1px solid #185fec;
    transition: .3s ease all;
}

._1ceqH ._1Yplu ._1Pz2d:hover {
    background: #fff;
    color: #185fec;
}

._1ceqH ._1Yplu ._2gd2_ {
    background: #fff;
    color: #185fec;
    margin-left: 15px;
}

._1ceqH ._1Yplu ._2gd2_:hover {
    background: #185fec;
    color: #fff;
}

._1ceqH ._f2DT8 {
    text-align: center;
}

._1ceqH ._17RTH {
    min-height: calc(100vh - 128px);
    background: #fff;
}

._1ceqH ._f2DT8 * {
    color: #fff;
    font-size: 60px;
}

._1ceqH ._1YOWG ._eV_dK {
    color: #fff;
}

._1ceqH ._qxztz {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    bottom: 0px;
    padding: 20px 0;
    display: flex;
}

._1ceqH ._qxztz ._2uz65 {
    width: 50px;
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    margin-right: 15px;
    font-size: 20px;
}

._1ceqH ._qxztz ._1bSom {
    background: #940505;
    position: relative;
}

._1ceqH ._qxztz ._3nQu5 {
    background: #9c6702;
    position: relative;
}


/* .recorder_library_box .microphone_icon{
  background-image: url('./imgs/microphone.png');
  background-repeat: no-repeat;
  display: block;
  width: 30px;
  height: 30px;
} */

._1ceqH ._3O0Io {
    width: 20px;
    height: 20px;
    border-style: solid;
    border-width: 10px 0px 10px 17px;
    border-color: transparent transparent transparent #fff;
    box-sizing: border-box;
    position: absolute;
    left: 56%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
}

._1ceqH ._2ACrw {
    width: 17px;
    height: 17px;
    border-style: double;
    border-width: 0px 0px 0px 15px;
    border-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

._1ceqH ._oEOY- {
    width: 17px;
    height: 17px;
    border: 1.5px solid #fff;
    border-radius: 2px;
}

._1ceqH ._3neb0 {
    width: 30px;
    height: 30px;
    display: flex;
}
.rce-container-mbox {
    flex-direction: column;
    display: block;
    overflow-x: hidden;
    min-width: 300px;
}

.rce-mbox-forward {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    background: #fff;
    position: absolute;
    /*display: none;*/
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px 0 rgba(164, 164, 164, 1);
    cursor: pointer;
    transition: all 0.3s ease;
    top: 0;
    bottom: 0;
    margin: auto;
}

.rce-mbox-forward-left {
    display: flex;
    opacity: 0;
    visibility: hidden;
    left: -50px;
}

.rce-mbox-forward-right {
    display: flex;
    opacity: 0;
    visibility: hidden;
    right: -50px;
}

.rce-mbox-reply-btn-left {
    display: flex;
    opacity: 0;
    visibility: hidden;
    left: -85px;
}

.rce-mbox-reply-btn-right {
    display: flex;
    opacity: 0;
    visibility: hidden;
    right: -85px;
}

.rce-container-mbox:hover .rce-mbox-forward-left {
    opacity: 1;
    visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-forward-right {
    opacity: 1;
    visibility: visible;
}

.rce-mbox-remove {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    background: #fff;
    position: absolute;
    /*display: none;*/
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px 0 rgba(164, 164, 164, 1);
    cursor: pointer;
    transition: all 0.3s ease;
    top: 0;
    bottom: 0;
    margin: auto;
}

.rce-mbox-remove-left {
    display: flex;
    opacity: 0;
    visibility: hidden;
    left: -120px;
}

.rce-mbox-remove-right {
    display: flex;
    opacity: 0;
    visibility: hidden;
    right: -120px;
}

.rce-container-mbox:hover .rce-mbox-remove-left {
    opacity: 1;
    visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-remove-right {
    opacity: 1;
    visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-reply-btn-left {
    opacity: 1;
    visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-reply-btn-right {
    opacity: 1;
    visibility: visible;
}

.rce-mbox {
    position: relative;
    background: white;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .2);
    border-top-left-radius: 0px;
    margin-left: 20px;
    margin-right: 5px;
    margin-top: 3px;
    flex-direction: column;
    margin-bottom: 3px;
    padding: 6px 9px 8px 9px;
    float: left;
    min-width: 140px;
}

.rce-mbox.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    animation-name: message-box-default-focus;
    -webkit-animation-name: message-box-default-focus;
    animation-duration: 1s;
}

@-webkit-keyframes message-box-default-focus {
    from {background-color: #fff;}
    to {background-color: #dfdfdf;}
}
@keyframes message-box-default-focus {
    from {background-color: #fff;}
    to {background-color: #dfdfdf;}
}

.rce-mbox-body {
    margin: 0;
    padding: 0;
    position: relative;
}

.rce-mbox.rce-mbox-right {
    float: right;
    margin-left: 5px;
    margin-right: 20px;
    border-top-right-radius: 0px;
    border-top-left-radius: 5px;
}

.rce-mbox.rce-mbox-right.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    -webkit-animation-name: message-box-right-focus;
            animation-name: message-box-right-focus;
    animation-duration: 1s;
}

@-webkit-keyframes message-box-right-focus {
    from {background-color: #d4f1fb;}
    to {background-color: #b8dae6;}
}
@keyframes message-box-right-focus {
    from {background-color: #d4f1fb;}
    to {background-color: #b8dae6;}
}
.rce-mbox-text {
    font-size: 13.6px;
    word-break: break-word;
}

.rce-mbox-text-retracted {
    font-style: italic;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex;
    align-items: center;
}

.rce-mbox-text.rce-mbox-text-retracted svg {
    margin-right: 3px;
}

.rce-mbox-text-retracted.left {
    color: #555555b3 !important;
}

.rce-mbox-text-retracted.right {
    color: #efefefb3 !important;
}

.rce-mbox-text:after {
    content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0"
}

.rce-mbox-time {
    text-align: right;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    position: absolute;
    right: -4px;
    bottom: -5px;
}

.rce-mbox-time.non-copiable:before {
    content: attr(data-text);
}

.rce-mbox-time-block {
    /*position: relative;*/
    right: 0;
    bottom: 0;
    left: 0;
    margin-right: -6px;
    margin-left: -6px;
    padding-top: 5px;
    padding-right: 3px;
    padding-bottom: 2px;
    background: linear-gradient(to top, rgba(0,0,0,0.33), transparent);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
}

.rce-mbox--clear-padding {
    padding-bottom: 3px;
}

.rce-mbox.rce-mbox--clear-notch {
    border-radius: 5px 5px 5px 5px !important;
}

.rce-mbox-right-notch {
    position: absolute;
    right: -14px;
    top: 0px;
    width: 15px;
    height: 15px;
    fill: white;
    -webkit-filter: drop-shadow( 2px 0px 1px rgba(0, 0, 0, .2));
            filter: drop-shadow( 2px 0px 1px rgba(0, 0, 0, .2));
}

.rce-mbox-right-notch.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    -webkit-animation-name: message-right-notch-focus;
    animation-name: message-right-notch-focus;
    animation-duration: 1s;
}

@-webkit-keyframes message-right-notch-focus {
    from {fill: #d4f1fb;}
    to {fill: #b8dae6;}
}
@keyframes message-right-notch-focus {
    from {fill: #d4f1fb;}
    to {fill: #b8dae6;}
}
.rce-mbox-left-notch {
    position: absolute;
    left: -14px;
    top: 0px;
    width: 15px;
    height: 15px;
    fill: white;
}

.rce-mbox-left-notch.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    animation-name: message-left-notch-focus;
    -webkit-animation-name: message-left-notch-focus;
    animation-duration: 1s;
}

@-webkit-keyframes message-left-notch-focus {
    from {fill: #fff;}
    to {fill: #dfdfdf;}
}
@keyframes message-left-notch-focus {
    from {fill: #fff;}
    to {fill: #dfdfdf;}
}

.rce-mbox-title {
    margin: 0;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 13px;
    color: #4f81a1;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.rce-mbox-title:hover {
    text-decoration: underline;
}

.rce-mbox-title--clear {
    margin-bottom: 5px;
}

.rce-mbox-status {
    margin-left: 3px;
    font-size: 15px;
}

.rce-mbox-title > .rce-avatar-container {
    margin-right: 5px;
}

.rce-mbox-photo {
    margin-top: -3px;
    margin-right: -6px;
    margin-left: -6px;
}

.rce-mbox-photo .rce-mbox-text {
    padding: 5px 0px;
    max-width: 300px;
    margin: auto;
}

.rce-mbox-photo--img {
    position: relative;
    display: flex;
    overflow: hidden;
    justify-content: center;
    border-radius: 5px;
    max-height: 300px;
}

.rce-mbox-photo--img__block {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    border-radius: 5px;
    display: flex;
}

.rce-mbox-photo--img img {
    height: 100%;
    min-height: 100px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.rce-mbox-photo--img__block-item {
    margin: auto;
    cursor: pointer;
    width: 100px;
    height: 100px;
}

.rce-mbox-photo--download {
    color: #efe;
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    font-size: 3.2em;
    outline: none;
    border: 1px solid #eee;
    border-radius: 100%;
    height: 100px;
    width: 100px;
}

.rce-mbox-photo--download:hover {
    opacity: .7;
}

.rce-mbox-photo--download:active {
    opacity: .3;
}

.rce-mbox-photo--error {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    font-size: 70px;
    color: #eaeaea;
}

.rce-mbox-file {
    margin-bottom: 3px
}

.rce-mbox-file > button {
    background: #e9e9e9;
    display: flex;
    border-radius: 5px;
    margin-top: -3px;
    margin-right: -6px;
    margin-left: -6px;
    align-items: center;
    min-height: 52px;
    max-width: 500px;
    padding: 5px 0;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    outline: none;
    border:none;
}

.rce-mbox-file > button > * {
    padding: 0px 10px;
}

.rce-mbox-file--icon {
    font-size: 30px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.rce-mbox-file--size {
    font-size: 10px;
    margin-top: 3px;
    max-width: 52px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rce-mbox-file--text {
    font-size: 13.6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rce-mbox-file--buttons {
    font-size: 30px;
    align-items: center;
    display: flex;
}
.rce-mbox-file--buttons .rce-error-button {
    display: inherit;
}

.rce-mbox-file--loading {
    font-size: 15px;
    width: 40px;
    height: 40px;
}

.rce-mbox-text {
    max-width: 300px;
}
.rce-container-smsg {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rce-smsg {
    position: relative;
    background: white;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,.2);
    display: flex;
    flex-direction: column;
    margin: 5px 0px;
    padding: 6px 9px 8px 9px;
    float: left;
    max-width: 70%;
    align-items: center;
    justify-content: center;
}

.rce-smsg-text {
    text-align: center;
    display: inline-block;
    font-size: 15px;
}

.rce-mbox-location {
    position: relative;
    width: 250px;
    height: 150px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -3px;
    margin-right: -6px;
    margin-left: -6px;
    border-radius: 5px;
}

.rce-mbox-location-img {
    width: 100%;
}

.rce-mbox-location-text {
    padding: 5px 0;
    width: 250px;
    margin-left: -6px;
    margin-right: -6px;
}

.rce-mbox-spotify {
    margin-top: -2px;
    overflow: hidden;
    margin-right: -6px;
    margin-left: -6px;
    display: flex;
    border-radius: 5px;
}

.rce-mbox-reply {
    position: relative;
    overflow: hidden;
    display: flex;
    margin-top: -3px;
    margin-bottom: 6px;
    margin-right: -6px;
    margin-left: -6px;
    border-radius: 5px;
    background: #ececec;
    padding: 0 5px;
    padding-right: 0;
    font-size: 12px;
    cursor: pointer;
    transition: 200ms;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.rce-mbox-reply.rce-mbox-reply-border {
    border-left: 5px solid;
}

.rce-mbox-reply:hover {
    opacity: 0.85;
}

.rce-mbox-reply-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3px 0;
    flex: 1 1;
}

.rce-mbox-reply-owner {
    font-size: 13px;
}

.rce-mbox-reply-message {
    color: #5a5a5a;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
}

.rce-mbox-reply-right {
    width: 40px;
    height: 40px;
}

.rce-mbox-reply-right img {
    width: 100%;
}

.rce-mbox-reply-text {
    padding: 5px 0;
    width: 250px;
    margin-left: -6px;
    margin-right: -6px;
}

.rce-mbox-mtmg {
    display: flex;
    justify-content: center;
    align-content: center;
    padding-bottom: 13px;
    min-width: 425px;
    max-width: 425px;
}

.rce-mtmg {
    width: 100%;
    position: relative;
    background: #f5f5f5;
    display: flex;
    flex-direction: column;
    margin: 5px 0px;
    float: left;
    border-radius: 2px;
}

.rce-mtmg-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 300px;
}

.rce-mtmg-subject {
    text-align: start;
    display: inline-block;
    font-size: 15px;
    padding: 5px 9px;
}

.rce-mtmg-toogleItem {
    width: 100%;
    height: 100%;
}

.rce-mtmg-body {
    height: 50px;
    background: #6264a7;
    color: white;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
}

.rce-mtmg-body:hover {
    opacity: 0.9;
}

.rce-mtmg-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 10px;
}

.rce-mtmg-item > svg {
    width: 23px;
    height: 23px;
}

.rce-mtmg-content {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
}

.rce-mtmg-date {
    color: #cecece;
    font-size: 13px;
}

.rce-mtmg-body-bottom {
    display: flex;
    flex-direction: row;
    padding: 9px;
    color: #6264a7;
    cursor: pointer;
    font-size: 13px;
}

.rce-mtmg-bottom--tptitle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rce-mtmg-bottom--tptitle > svg,
.rce-mtmg-body-bottom--bttitle > svg {
    color: #6264a7a1;
}

.rce-mtmg-toogleContent {
    display: none;
    height: auto;
    min-height: 60px;
    flex-direction: column;
}

.rce-mtmg-toogleContent--click {
    display: flex;
}

.rce-mtmg-right-icon {
    right: 10px;
    cursor: pointer;
    height: 100%;
    background: transparent !important;
}

.rce-mtmg-body .rce-dropdown-container {
    height: 100%;
}

.rce-mtmg-right-icon > svg {
    width: 23px;
    height: 23px;
}

.rce-mitem {
    display: flex;
    padding: 10px 8px;
}

.rce-mitem:hover,
.rce-mitem-event:hover {
    background: #ececec;
}

.rce-mitem-event {
    -webkit-user-select:  none;
        -ms-user-select:  none;
            user-select:  none;
}

.rce-mitem-body {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.rce-mitem-body-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.rce-mitem-body--top {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.rce-mitem-body--top-title {
    font-size: 15px;
    color: #6264a7;
    padding: 0px 15px 0 0;
    text-transform: capitalize;
    font-weight: 600;
}

.rce-mitem-body--top-title:hover {
    cursor: pointer;
    text-decoration: underline;
}

.rce-mitem-body--bottom-title {
    color: #252525;
    font-size: 13px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.rce-mitem-body--top-time {
    font-size: 12px;
    color: rgba(0,0,0,0.4);
}

.rce-mitem-bottom-body {
    padding: 10px 8px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.rce-mitem-body.avatar {
    padding: 8px 10px 0 0;
}

.rce-mitem.avatar {
    padding: 8px 5px 0 0;
}

.rce-mitem.no-avatar {
    padding: 8px 10px 0 0;
}

.rce-mitem.no-avatar > svg {
    width: 19px;
    height: 19px;
}

.rce-mitem.avatar img {
    width: 22px;
    height: 22px;
    border: none !important;
    background: #ccc;
    border-radius: 100%;
}

.rce-mitem-body.avatar > svg {
    width: 19px;
    height: 19px;
}

.rce-mitem-bottom-body-top {
    display: flex;
    flex-direction: column;
}

.rce-mitem-bottom-body-top-title > svg {
    padding: 0 7px 0 0;
}

.rce-mitem-avatar-content {
    position: absolute;
    right: 10px;
    display: flex;
}

.rce-mitem-avatar {
    padding: 0 3px 0 0;
    display: flex;
}

.rce-mitem-tooltip {
    display: inline;
    position: relative;
}

.rce-mitem-tooltip-text {
    margin: 5px
}

.rce-mitem-tooltip-text:after{
    content: "";
    left: 15%;
    top: 29px;
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 11px solid #444;
    opacity: 0;
    transition: opacity 0.8s linear 0.2s;
  }

.rce-mitem-tooltip[tooltip]:after {
    display: flex;
    justify-content: center;
    background: #444;
    border-radius: 8px;
    color: #fff;
    content: attr(tooltip);
    font-size: 14px;
    padding: 5px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.8s linear 0.2s;
    min-width: 415px;
    max-width: 415px;
    top: 40px;
    right: -13px;
    z-index: 1;
}

.rce-mitem-tooltip-text:hover:after {
    opacity: 1;
    transition: opacity 0.8s linear;
}

.rce-mitem-tooltip[tooltip]:hover:after {
    opacity: 1;
    transition: opacity 0.8s linear  0.1s;
}

.rce-mitem-tooltip[tooltip]:hover .rce-mitem-tooltip-text:after {
    opacity: 1;
}

.rce-mitem-length {
    color: #fff;
    font-size: 14px;
    background: #e48989;
    display: flex;
    align-items: center;
    text-align: center;
    width: 25px;
    height: 25px;
    display: flex;
    border-radius: 50%;
}

.rce-mitem-avatar img {
    width: 50px;
    height: 50px;
    border: none !important;
    background: #ccc;
    border-radius: 100%;
}

.rce-mtmg-call-record {
    width: 350px;
    height: 85%;
    background: #eaeaea;
    margin-top: 11px;
}

.rce-mtmg-call-body {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    position: relative;
}

.rce-mtmg-call-avatars {
    width: 140px !important;
    height: 100px !important;
    position: relative;
}

.rce-mtmg-call-avatars img {
    width: 100% !important;
    height: 100% !important;
    background: #ccc;
    cursor: pointer;
}

.rce-mtmg-call-body-title {
    display: flex;
    position: relative;
    flex-direction: column;
    top: 30px;
    left: 15px;
}

.rce-mtmg-call-body-title > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 185px;
}

.rce-mtmg-call-body-bottom {
    color: #505050;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 185px;
    font-size: 12px;
}

.rce-mtmg-record-time {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 13px;
    background: #000000cf;
    color: white;
    padding: 4px;
    border-radius: 5px;
}

.rce-avatar-container {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rce-avatar-container .rce-avatar {
    width: 100%;
    height: 100%;
}

.rce-avatar-container.flexible .rce-avatar {
    height: auto !important;
    width: 100% !important;
    border-radius: unset !important;
    overflow: unset !important;
}

.rce-avatar-container.default{
    width: 25px;
    height: 25px;
}

.rce-avatar-container.rounded{
    border-radius: 5px;
}

.rce-avatar-container.circle{
    border-radius: 100%;
}

.rce-avatar-container.xsmall{
    width: 30px;
    height: 30px;
}

.rce-avatar-container.small{
    width: 35px;
    height: 35px;
}

.rce-avatar-container.medium{
    width: 40px;
    height: 40px;
}

.rce-avatar-container.large{
    width: 45px;
    height: 45px;
}

.rce-avatar-container.xlarge{
    width: 55px;
    height: 55px;
}

@-webkit-keyframes avatarLazy {
    0% {
        opacity:1;
    }
    50% {
        opacity:.5;
    }
    100% {
        opacity:1;
    }
}

@keyframes avatarLazy {
    0% {
        opacity:1;
    }
    50% {
        opacity:.5;
    }
    100% {
        opacity:1;
    }
}

.rce-avatar-lazy {
    -webkit-animation: avatarLazy normal 2s infinite ease-in-out;
            animation: avatarLazy normal 2s infinite ease-in-out;
}

.rce-avatar-container.rce-citem-avatar-encircle-status {
    box-sizing: border-box;
    position: relative;
}

.rce-avatar-letter {
    margin-top: 5px;
    font-size: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rce-avatar-letter-background{
    height: 35px;
    width: 35px;
    border-radius: 20px;
}

.rce-dropdown-container {
    position: relative;
}
.rce-dropdown {
    min-width: 100%;
    box-sizing: border-box;
    padding: 8px 15px;
    background: #fff;
    border-radius: 5px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    -webkit-transform: scale(0);
            transform: scale(0);
    position: absolute;
    box-shadow: 0px 0px 5px 0px rgba(163, 163, 163, 1);
    -webkit-transform-origin: left top;
            transform-origin: left top;
    z-index: 99999;
}

.rce-dropdown-title {
    font-size: 14px;
    color: '#000';
    font-weight: 500;
}

.rce-dropdown.dropdown-show{
    -webkit-animation: dropdown-scaling 0.2s ease forwards;
            animation: dropdown-scaling 0.2s ease forwards;
    display: flex;
}

@-webkit-keyframes dropdown-scaling {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes dropdown-scaling {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.rce-dropdown.dropdown-hide{
    -webkit-animation: dropdown-reverse-scaling 0.2s ease forwards;
            animation: dropdown-reverse-scaling 0.2s ease forwards;
    display: flex;
}

@-webkit-keyframes dropdown-reverse-scaling {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        opacity: 0.5;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}

@keyframes dropdown-reverse-scaling {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        opacity: 0.5;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}

.rce-dropdown-open__nortwest {
    -webkit-transform-origin: left top;
            transform-origin: left top;
    left: 0;
    top: 100%;
    margin-top: 5px
}

.rce-dropdown-open__norteast {
    -webkit-transform-origin: right top;
            transform-origin: right top;
    right: 0;
    top: 100%;
    margin-top: 5px
}

.rce-dropdown-open__southwest {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    left: 0;
    bottom: 100%;
    margin-bottom: 5px
}

.rce-dropdown-open__southeast {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    right: 0;
    bottom: 100%;
    margin-bottom: 5px
}

.rce-dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rce-dropdown ul li {
    white-space: nowrap;
    color: #767676;
    padding: 8px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
    box-sizing: border-box;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.rce-dropdown ul li:last-child {
    border: none;
}

.rce-dropdown ul li:hover a {
    color: #3a6d8c;
}

.rce-dropdown.fade {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation: dropdown-fade 0.5s ease forwards;
            animation: dropdown-fade 0.5s ease forwards;
}

@-webkit-keyframes dropdown-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes dropdown-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.rce-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    padding: 8px;
    text-align: center;
    box-sizing: border-box;
    background: #3979aa;
    color: white;
    transition: all 0.15s ease;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border: none;
    outline: none;
    border: none;
    position: relative;
}

.rce-button-icon--container {
    display: flex;
    align-items: center;
}

.rce-button:hover {
    opacity: 0.8;
}

.rce-button:active {
    opacity: 0.6;
}

.rce-button.outline {
    background: rgba(0, 0, 0, 0) !important;
    border: 1px solid #3979aa;
    color: #3979aa;
}

.rce-button.outline:hover {
    opacity: 0.6;
}

.rce-button.outline:active {
    opacity: 0.3;
}

.rce-button.transparent {
    background: rgba(0, 0, 0, 0) !important;
}

.rce-button.transparent:hover {
    opacity: 0.6;
}

.rce-button.transparent:active {
    opacity: 0.3;
}

.rce-button-icon {
    position: relative;
    font-size: 18px;
    display: flex;
    padding: 0 3px;
}

.rce-button-badge{
    border-radius: 4px;
    padding: 4px;
    background: #f64b34;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -7px;
    top:-7px;
    font-size: 10px;
}

.rce-button.circle {
    min-width: 35px;
    min-height: 35px;
    border: 1px solid #3979aa;
    border-radius: 100%;
}

.rce-mbox-video {
    margin-top: -3px;
    margin-right: -6px;
    margin-left: -6px;
}

.rce-mbox-video.padding-time {
    padding-bottom: 12px;
}

.rce-mbox-video .rce-mbox-text {
    padding: 5px 0px;
    max-width: 300px;
    margin: auto;
}

.rce-mbox-video--video {
    position: relative;
    display: flex;
    overflow: hidden;
    justify-content: center;
    border-radius: 5px;
    max-height: 500px;
}

.rce-mbox-video--video__block {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    border-radius: 5px;
    display: flex;
}

.rce-mbox-video--video img {
    height: 100%;
    min-height: 100px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.rce-mbox-video--video video {
    width: 100%;
    /*min-height: 100px;*/
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.rce-mbox-video--video__block-item {
    margin: auto;
    cursor: pointer;
    width: 100px;
    height: 100px;
}

.rce-mbox-video--download {
    color: #efe;
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    font-size: 3.2em;
    outline: none;
    border: 1px solid #eee;
    border-radius: 100%;
    height: 100px;
    width: 100px;
}

.rce-mbox-video--download:hover {
    opacity: .7;
}

.rce-mbox-video--download:active {
    opacity: .3;
}

.rce-mbox-video--error {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    font-size: 70px;
    color: #eaeaea;
}

.rce-mbox-audio {
    padding-bottom: 10px;
    max-width: 300px;
}

audio:focus {
    outline: none;
}
.rce-container-citem {
    flex-direction: column;
    display: block;
    overflow: hidden;
    min-width: 240px;
}

.rce-container-citem.rce-citem-status-encircle {
    position: relative;
}

.rce-citem {
    position: relative;
    background: white;
    display: flex;
    flex-direction: row;
    height: 72px;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    max-width: 100%;
    overflow: hidden;
    min-width: 240px;
}

.rce-citem:hover {
    background: #f9f9f9;
}

.rce-citem-avatar {
    position: relative;
    padding: 0 15px 0 13px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.rce-citem-status {
    width: 20px;
    height: 20px;
    bottom: 10px;
    right: 10px;
    position: absolute;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ccc;
}

.rce-citem-avatar.rce-citem-status-encircle .rce-citem-status {
    left:0;
    right:0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    margin: auto;
    border-radius: 100%;
}

.rce-citem-avatar img {
    width: 50px;
    height: 50px;
    border: none !important;
    background: #ccc;
    border-radius: 100%;
    overflow: hidden;
    font-size: 10px;
    text-align: center;
    line-height: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rce-citem-body {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    padding-right: 15px;
    border-bottom: 1px solid rgba(0,0,0,.05);
    overflow: hidden;
}

.rce-citem-body--top {
    display: flex;
}

.rce-citem-body--bottom {
    margin-top: 4px;
    display: flex;
}

.rce-citem-body--bottom-title, .rce-citem-body--top-title {
    flex: 1 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.rce-citem-body--top-title {
    font-size: 16px;
}

.rce-citem-body--bottom-title {
    color: #555;
    font-size: 15px;
}

.rce-citem-body--top-time {
    font-size: 12px;
    color: rgba(0,0,0,0.4)
}

.rce-citem-body--bottom-status {
    margin-left: 3px;
}

.rce-citem-body--bottom-status span {
    width: 18px;
    height: 18px;
    font-size: 12px;
    color: white;
    font-weight: bold;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 100%;
    background: red;
}

.rce-citem-body--bottom-tools {
    align-items: center;
    justify-content: center;
    flex-direction: row;
    display: flex;
    height: 0;
    opacity: 0;
    position: absolute;
    left: -999px;
    transition: height .5s ease, opacity 1s ease;
}

.rce-citem:hover .rce-citem-body--bottom-tools {
    height: 100%;
    opacity: 1;
    position: relative;
    left: 0;
}

.rce-citem-body--bottom-tools-item-hidden-hover {
    height: 100%;
    opacity: 0.3;
    transition: .5s ease;
}

.rce-citem:hover .rce-citem-body--bottom-tools-item-hidden-hover {
    height: 0;
    opacity: 0;
    position: absolute;
    left: -999px;
    transition: .5s ease;
}

.rce-citem-body--bottom-tools-item {
    width: 18px;
    height: 18px;
}

.rce-citem-body--bottom-tools-item svg {
    width: 18px;
    height: 18px;
    fill: #575757;
}
.rce-container-clist {
    display: block;
    overflow: auto;
}

.rce-container-mlist {
    position: relative;
    display: flex;
}

.rce-mlist {
    display: block;
    overflow: auto;
    position: relative;
    flex: 1 1;
}

.rce-mlist-down-button {
    position: absolute;
    right: 10px;
    bottom: 15px;
    width: 40px;
    height: 40px;
    background: #fff;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.05), 0 2px 5px 0 rgba(0,0,0,0.1);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    cursor: pointer;
    transition: 200ms;
}

.rce-mlist-down-button:hover {
    opacity: 0.7;
}

.rce-mlist-down-button--badge {
    position: absolute;
    right: -5px;
    top: -5px;
    background: red;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    font-size: 12px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 700;
}

.rce-container-input {
    display: flex;
    min-width: 100%;
    box-sizing: border-box;
    flex-direction: row;
    background: #fff;
    align-items: center;
}

.rce-input {
    flex: 1 1;
    height: 40px;
    padding: 0 5px;
    border: none;
    border-radius: 5px;
    color: #333;
    font-size: 14px;
    box-sizing: border-box;
    outline: none;
}

.rce-input-textarea {
    height: 37px;
    padding: 10px 5px;
    resize: none;
}

.rce-input-buttons {
    display: flex;
    flex-direction: row;
    margin: 5px;
}

.rce-input-buttons > * {
    display: flex;
    flex-direction: row;
}

.rce-input-buttons .rce-button:nth-child(even) {
    margin-left: 5px;
    margin-right: 5px;
}

.rce-input-buttons .rce-button:last-child {
    margin-right: 0;
}

.rce-left-padding {
    padding-left: 10px;
    padding-right: 0px !important;
}

.rce-right-padding {
    padding-right: 10px;
    padding-left: 0px !important;
}

.rce-input::-webkit-input-placeholder {
    color: #afafaf;
}

.rce-input:-ms-input-placeholder {
    color: #afafaf;
}

.rce-input::placeholder {
    color: #afafaf;
}

.rce-navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
}

.rce-navbar.light {
    background: #f4f4f4;
}

.rce-navbar.dark {
    background: #2f414c;
}

.rce-navbar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.rce-navbar-item > * {
    display: flex;
    flex-direction: row;
}

.rce-navbar-item > * > * {
    margin-left: 5px;
}
.rce-sbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
    min-height: 100%;
}

.rce-sbar.light {
    background: #f4f4f4;
}

.rce-sbar.dark {
    background: #2f414c;
}

.rce-sbar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
}

.rce-sbar-item > * {
    display: flex;
    flex-direction: column;
}

.rce-sbar-item__center {
    margin: 15px 0;
}
.rce-popup-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: rgba(255, 255, 255, 0.7);
    z-index: 9999999999;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.rce-popup {
    background: #fff;
    border-radius: 5px;
    padding: 0 10px 0 10px;
    width: 400px;
    min-height: 100px;
    box-shadow: 0px 0px 25px -2px rgba(79, 79, 79, 1);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    -webkit-animation: popup-scaling 0.4s ease forwards;
            animation: popup-scaling 0.4s ease forwards;
    box-sizing: border-box;
}

@-webkit-keyframes popup-scaling {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 0;
    }
    50% {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}

@keyframes popup-scaling {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 0;
    }
    50% {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}

.rce-popup-header {
    padding: 18px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

.rce-popup-content {
    padding: 8px;
    font-size: 14px;
    box-sizing: border-box;
}

.rce-popup-content * {
    margin: 0
}

.rce-popup-footer {
    padding: 18px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
}

.rce-popup-footer>* {
    margin-left: 5px;
}
.rce-container-mtitem {
    flex-direction: column;
    display: block;
    overflow: hidden;
    min-width: 240px;
}

.rce-mtitem {
    position: relative;
    background: white;
    display: flex;
    flex-direction: column;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    max-width: 100%;
    overflow: hidden;
    min-width: 240px;
    border-bottom: 1px solid rgba(0,0,0,.05);
}

.rce-mtitem:hover {
    background: #fbfbfb;
}

.rce-mtitem-top {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
}

.rce-mtitem-subject {
    padding: 0 10px;
    margin-top: 5px;
    font-size: 15px;
    overflow: hidden;
    color: #333;
    max-height: 35px;
    text-overflow: ellipsis;
}

.rce-mtitem-body {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    display: flex;
    justify-content: center;
    padding: 0 10px;
    overflow: hidden;
    align-items: center;
}

.rce-mtitem-body--avatars {
    display: flex;
    flex: 1 1;
    overflow: hidden;
    opacity: 0.7;
}

.rce-mtitem-body--functions {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.rce-mtitem-footer {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.rce-mtitem-body--avatars .rce-avatar-container {
    margin-left: -10px;
    border: 2px solid #fff;
}

.rce-mtitem-body--avatars .rce-avatar-container:first-child {
    margin: 0;
}

.rce-mtitem-letter {
    color: #fff;
    background: #e48989;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rce-mtitem-button {
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #5ba7c5;
    border-radius: 100%;
    padding: 3px;
    transition: 300ms;
}

.rce-mtitem-closable {
    font-size: 25px;
    color: #fff;
    background:#ff4a4f;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 3px;
    margin-right: 7px;
}

.rce-mtitem:hover .rce-mtitem-closable {
    display: flex;
    cursor: pointer;
}

.rce-mtitem-share {
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5ba7c5;
    margin: -10px 0;
    transition: 300ms;
    position: relative;
    left: -10px;
}

.rce-mtitem-button:hover,
.rce-mtitem-share:hover {
    opacity: 0.8;
    cursor: pointer;
}

.rce-mtitem-date {
    color: #9f9f9f;
    font-size: 13px;
}

.rce-container-mtlist {
    display: block;
    overflow: auto;
}

.rce-mbox-video {
    margin-top: -3px;
    margin-right: -6px;
    margin-left: -6px;
    width: 100%;
}

.rce-mbox-video.padding-time {
    padding-bottom: 12px;
}

.rce-mbox-video .rce-mbox-text {
    padding: 5px 0px;
    max-width: 300px;
    margin: auto;
}

.rce-mbox-video--video {
    position: relative;
    display: flex;
    overflow: hidden;
    justify-content: center;
    border-radius: 5px;
    max-height: 500px;
   
}

.rce-mbox-video--video__block {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    border-radius: 5px;
    display: flex;
}

.rce-mbox-video--video img {
    height: 100%;
    min-height: 100px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  
}

.rce-mbox-video--video video {
    width: 100%;
    min-height: 100px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.rce-mbox-video--video__block-item {
    margin: auto;
    cursor: pointer;
    width: 100px;
    height: 100px;
}

.rce-mbox-video--download {
    color: #efe;
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    font-size: 3.2em;
    outline: none;
    border: 1px solid #eee;
    border-radius: 100%;
    height: 100px;
    width: 100px;
}

.rce-mbox-video--download:hover {
    opacity: .7;
}

.rce-mbox-video--download:active {
    opacity: .3;
}

.rce-mbox-video--error {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    font-size: 70px;
    color: #eaeaea;
}
.rce-container-mbox {
    flex-direction: column;
    display: block;
    overflow-x: hidden;
    min-width: 200px;
}

.rce-mbox-forward {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    background: #FAFAFA;
    position: absolute;
    /*display: none;*/
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px 0 rgba(164, 164, 164, 1);
    cursor: pointer;
    transition: all 0.3s ease;
    top: 0;
    bottom: 0;
    margin: auto;
}

.rce-mbox-forward-left {
    display: flex;
    opacity: 0;
    visibility: hidden;
    left: -50px;
}

.rce-mbox-forward-right {
    display: flex;
    opacity: 0;
    visibility: hidden;
    right: -50px;
}

.rce-mbox-reply-btn-left {
    display: flex;
    opacity: 0;
    visibility: hidden;
    left: -85px;
}

.rce-mbox-reply-btn-right {
    display: flex;
    opacity: 0;
    visibility: hidden;
    right: -85px;
}

.rce-container-mbox:hover .rce-mbox-forward-left {
    opacity: 1;
    visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-forward-right {
    opacity: 1;
    visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-reply-btn-left {
    opacity: 1;
    visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-reply-btn-right {
    opacity: 1;
    visibility: visible;
}

.rce-mbox {
    position: relative;
    background: white;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .2);
    border-top-left-radius: 0px;
    margin-left: 20px;
    margin-right: 5px;
    margin-top: 3px;
    flex-direction: column;
    margin-bottom: 3px;
    padding: 6px 9px 8px 9px;
    float: left;
    min-width: 140px;
    max-width:85%;
}

.rce-mbox.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    -webkit-animation-name: message-box-default-focus;
            animation-name: message-box-default-focus;
    animation-duration: 1s;
}

@-webkit-keyframes message-box-default-focus {
    from {background-color: #FAFAFA;}
    to {background-color: #808080;}
}

.rce-mbox-body {
    margin: 0;
    padding: 0;
    position: relative;
}

.rce-mbox.rce-mbox-right {
    float: right;
    margin-left: 5px;
    margin-right: 20px;
    border-top-right-radius: 0px;
    border-top-left-radius: 5px;
    background:red;
}

.rce-mbox.rce-mbox-right.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    -webkit-animation-name: message-box-right-focus;
            animation-name: message-box-right-focus;
    animation-duration: 1s;
}

@-webkit-keyframes message-box-right-focus {
    from {background-color: #d4f1fb;}
    to {background-color: #808080;}
}

.rce-mbox-text {
    font-size: 16px;
    word-break: break-word;
white-space: pre-wrap;
width:100%;
}

.rce-mbox-text:after {
    content: "\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0"
}

.rce-mbox-time {
    text-align: right;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    position: absolute;
    right: -4px;
    bottom: -5px;
}

.rce-mbox-time.non-copiable:before {
    content: attr(data-text);
}

.rce-mbox-time-block {
    /*position: relative;*/
    right: 0;
    bottom: 0;
    left: 0;
    margin-right: -6px;
    margin-left: -6px;
    padding-top: 5px;
    padding-right: 3px;
    padding-bottom: 2px;
    background: linear-gradient(to top, rgba(0,0,0,0.33), transparent);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #FAFAFA;
}

.rce-mbox--clear-padding {
    padding-bottom: 3px;
}

.rce-mbox.rce-mbox--clear-notch {
    border-radius: 5px 5px 5px 5px !important;
}

.rce-mbox-right-notch {
    position: absolute;
    right: -14px;
    top: 0px;
    width: 15px;
    height: 15px;
    fill: #d4f1fb;
    -webkit-filter: drop-shadow( 2px 0px 1px rgba(0, 0, 0, .2));
            filter: drop-shadow( 2px 0px 1px rgba(0, 0, 0, .2));
}

.rce-mbox-right-notch.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    -webkit-animation-name: message-right-notch-focus;
            animation-name: message-right-notch-focus;
    animation-duration: 1s;
}

@-webkit-keyframes message-right-notch-focus {
    from {fill: #d4f1fb;}
    to {fill: #808080;}
}

.rce-mbox-left-notch {
    position: absolute;
    left: -14px;
    top: 0px;
    width: 15px;
    height: 15px;
    fill: #FAFAFA;
}

.rce-mbox-left-notch.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    -webkit-animation-name: message-left-notch-focus;
            animation-name: message-left-notch-focus;
    animation-duration: 1s;
}

@-webkit-keyframes message-left-notch-focus {
    from {fill: #FAFAFA;}
    to {fill: #808080;}
}

.rce-mbox-title {
    margin: 0;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 15px;
    color: #4f81a1;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
   /*  cursor: pointer; */
    display: flex;
    align-items: center;
}

.rce-mbox-title:hover {
   /*  text-decoration: underline; */
}

.rce-mbox-title--clear {
    margin-bottom: 5px;
}

.rce-mbox-status {
    margin-left: 3px;
    font-size: 15px;
}

.rce-mbox-title > .rce-avatar-container {
    margin-right: 5px;
}
.rce-mbox-file {
    margin-bottom: 3px;
}

.rce-mbox-file > button {
    background: #e9e9e9;
    display: flex;
    border-radius: 5px;
    margin-top: -3px;
    margin-right: -6px;
    margin-left: -6px;
    align-items: center;
    min-height: 52px;
    max-width: 500px;
    padding: 5px 0;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    outline: none;
    border:none;
}

.rce-mbox-file > button > * {
    padding: 0px 10px;
}

.rce-mbox-file--icon {
    font-size: 30px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.rce-mbox-file--size {
    font-size: 10px;
    margin-top: 3px;
    max-width: 52px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rce-mbox-file--text {
    font-size: 13.6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rce-mbox-file--buttons {
    font-size: 30px;
    align-items: center;
    display: flex;
}
.rce-mbox-file--buttons .rce-error-button {
    display: inherit;
}

.rce-mbox-file--loading {
    font-size: 15px;
    width: 40px;
    height: 40px;
}
.rce-container-smsg {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rce-smsg {
    position: relative;
    background: white;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,.2);
    display: flex;
    flex-direction: column;
    margin: 5px 0px;
    padding: 6px 9px 8px 9px;
    float: left;
    max-width: 70%;
    align-items: center;
    justify-content: center;
}

.rce-smsg-text {
    text-align: center;
    display: inline-block;
    font-size: 15px;
    white-space: pre-wrap;
}
.rce-mbox-location {
    position: relative;
    width: 250px;
    height: 150px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -3px;
    margin-right: -6px;
    margin-left: -6px;
    border-radius: 5px;
}

.rce-mbox-location-img {
    width: 100%;
}

.rce-mbox-location-text {
    padding: 5px 0;
    width: 250px;
    margin-left: -6px;
    margin-right: -6px;
}
.rce-mbox-spotify {
    margin-top: -2px;
    overflow: hidden;
    margin-right: -6px;
    margin-left: -6px;
    display: flex;
    border-radius: 5px;
}
.rce-mbox-reply {
    position: relative;
    overflow: hidden;
    display: flex;
    margin-top: -3px;
    margin-bottom: 6px;
    margin-right: -6px;
    margin-left: -6px;
    border-radius: 5px;
    background: #ececec;
    padding: 0 5px;
    padding-right: 0;
    font-size: 12px;
    cursor: pointer;
    transition: 200ms;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.rce-mbox-reply.rce-mbox-reply-border {
    border-left: 5px solid;
}

.rce-mbox-reply:hover {
    opacity: 0.85;
}

.rce-mbox-reply-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3px 0;
    flex: 1 1;
}

.rce-mbox-reply-owner {
    font-size: 13px;

}

.rce-mbox-reply-message {
    color: #5a5a5a;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;

}

.rce-mbox-reply-right {
    width: 40px;
    height: 40px;
}

.rce-mbox-reply-right img {
    width: 100%;
}

.rce-mbox-reply-text {
    padding: 5px 0;
    width: 250px;
    margin-left: -6px;
    margin-right: -6px;
}
.rce-mbox-mtmg {
    display: flex;
    justify-content: center;
    align-content: center;
    padding-bottom: 13px;
    min-width: 425px;
    max-width: 425px;
}

.rce-mtmg {
    width: 100%;
    position: relative;
    background: #f5f5f5;
    display: flex;
    flex-direction: column;
    margin: 5px 0px;
    float: left;
    border-radius: 2px;
}

.rce-mtmg-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 300px;
}

.rce-mtmg-subject {
    text-align: start;
    display: inline-block;
    font-size: 15px;
    padding: 5px 9px;
}

.rce-mtmg-toogleItem {
    width: 100%;
    height: 100%;
}

.rce-mtmg-body {
    height: 50px;
    background: #6264a7;
    color: white;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
}

.rce-mtmg-body:hover {
    opacity: 0.9;
}

.rce-mtmg-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 10px;
}

.rce-mtmg-item > svg {
    width: 23px;
    height: 23px;
}

.rce-mtmg-content {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
}

.rce-mtmg-date {
    color: #cecece;
    font-size: 13px;
}

.rce-mtmg-body-bottom {
    display: flex;
    flex-direction: row;
    padding: 9px;
    color: #6264a7;
    cursor: pointer;
    font-size: 13px;
}

.rce-mtmg-bottom--tptitle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rce-mtmg-bottom--tptitle > svg,
.rce-mtmg-body-bottom--bttitle > svg {
    color: #6264a7a1;
}

.rce-mtmg-toogleContent {
    display: none;
    height: auto;
    min-height: 60px;
    flex-direction: column;
}

.rce-mtmg-toogleContent--click {
    display: flex;
}

.rce-mtmg-right-icon {
    right: 10px;
    cursor: pointer;
    height: 100%;
    background: transparent !important;
}

.rce-mtmg-body .rce-dropdown-container {
    height: 100%;
}

.rce-mtmg-right-icon > svg {
    width: 23px;
    height: 23px;
}

.rce-mitem {
    display: flex;
    padding: 10px 8px;
}

.rce-mitem:hover,
.rce-mitem-event:hover {
    background: #ececec;
}

.rce-mitem-event {
    -webkit-user-select:  none;
        -ms-user-select:  none;
            user-select:  none;
}

.rce-mitem-body {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.rce-mitem-body-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.rce-mitem-body--top {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.rce-mitem-body--top-title {
    font-size: 15px;
    color: #6264a7;
    padding: 0px 15px 0 0;
    text-transform: capitalize;
    font-weight: 600;
}

.rce-mitem-body--top-title:hover {
    cursor: pointer;
    text-decoration: underline;
}

.rce-mitem-body--bottom-title {
    color: #252525;
    font-size: 13px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.rce-mitem-body--top-time {
    font-size: 12px;
    color: rgba(0,0,0,0.4);
}

.rce-mitem-bottom-body {
    padding: 10px 8px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.rce-mitem-body.avatar {
    padding: 8px 10px 0 0;
}

.rce-mitem.avatar {
    padding: 8px 5px 0 0;
}

.rce-mitem.no-avatar {
    padding: 8px 10px 0 0;
}

.rce-mitem.no-avatar > svg {
    width: 19px;
    height: 19px;
}

.rce-mitem.avatar img {
    width: 22px;
    height: 22px;
    border: none !important;
    background: #ccc;
    border-radius: 100%;
}

.rce-mitem-body.avatar > svg {
    width: 19px;
    height: 19px;
}

.rce-mitem-bottom-body-top {
    display: flex;
    flex-direction: column;
}

.rce-mitem-bottom-body-top-title > svg {
    padding: 0 7px 0 0;
}

.rce-mitem-avatar-content {
    position: absolute;
    right: 10px;
    display: flex;
}

.rce-mitem-avatar {
    padding: 0 3px 0 0;
    display: flex;
}

.rce-mitem-tooltip {
    display: inline;
    position: relative;
}

.rce-mitem-tooltip-text {
    margin: 5px
}

.rce-mitem-tooltip-text:after{
    content: "";
    left: 15%;
    top: 29px;
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 11px solid #444;
    opacity: 0;
    transition: opacity 0.8s linear 0.2s;
  }

.rce-mitem-tooltip[tooltip]:after {
    display: flex;
    justify-content: center;
    background: #444;
    border-radius: 8px;
    color: #FAFAFA;
    content: attr(tooltip);
    font-size: 14px;
    padding: 5px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.8s linear 0.2s;
    min-width: 415px;
    max-width: 415px;
    top: 40px;
    right: -13px;
    z-index: 1;
}

.rce-mitem-tooltip-text:hover:after {
    opacity: 1;
    transition: opacity 0.8s linear;
}

.rce-mitem-tooltip[tooltip]:hover:after {
    opacity: 1;
    transition: opacity 0.8s linear  0.1s;
}

.rce-mitem-tooltip[tooltip]:hover .rce-mitem-tooltip-text:after {
    opacity: 1;
}

.rce-mitem-length {
    color: #FAFAFA;
    font-size: 14px;
    background: #e48989;
    display: flex;
    align-items: center;
    text-align: center;
    width: 25px;
    height: 25px;
    display: flex;
    border-radius: 50%;
}

.rce-mitem-avatar img {
    width: 50px;
    height: 50px;
    border: none !important;
    background: #ccc;
    border-radius: 100%;
}

.rce-mtmg-call-record {
    width: 350px;
    height: 85%;
    background: #eaeaea;
    margin-top: 11px;
}

.rce-mtmg-call-body {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    position: relative;
}

.rce-mtmg-call-avatars {
    width: 140px !important;
    height: 100px !important;
    position: relative;
}

.rce-mtmg-call-avatars img {
    width: 100% !important;
    height: 100% !important;
    background: #ccc;
    cursor: pointer;
}

.rce-mtmg-call-body-title {
    display: flex;
    position: relative;
    flex-direction: column;
    top: 30px;
    left: 15px;
}

.rce-mtmg-call-body-title > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 185px;
}

.rce-mtmg-call-body-bottom {
    color: #505050;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 185px;
    font-size: 12px;
}

.rce-mtmg-record-time {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 13px;
    background: #000000cf;
    color: white;
    padding: 4px;
    border-radius: 5px;
}
.rce-avatar-container {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rce-avatar-container .rce-avatar {
    width: 100%;
    height: 100%;
}

.rce-avatar-container.flexible .rce-avatar {
    height: auto !important;
    width: 100% !important;
    border-radius: unset !important;
    overflow: unset !important;
}

.rce-avatar-container.default{
    width: 25px;
    height: 25px;
}

.rce-avatar-container.rounded{
    border-radius: 5px;
}

.rce-avatar-container.circle{
    border-radius: 100%;
}

.rce-avatar-container.xsmall{
    width: 30px;
    height: 30px;
}

.rce-avatar-container.small{
    width: 35px;
    height: 35px;
}

.rce-avatar-container.medium{
    width: 40px;
    height: 40px;
}

.rce-avatar-container.large{
    width: 45px;
    height: 45px;
}

.rce-avatar-container.xlarge{
    width: 55px;
    height: 55px;
}

@-webkit-keyframes avatarLazy {
    0% {
        opacity:1;
    }
    50% {
        opacity:.5;
    }
    100% {
        opacity:1;
    }
}

@keyframes avatarLazy {
    0% {
        opacity:1;
    }
    50% {
        opacity:.5;
    }
    100% {
        opacity:1;
    }
}

.rce-avatar-lazy {
    -webkit-animation: avatarLazy normal 2s infinite ease-in-out;
            animation: avatarLazy normal 2s infinite ease-in-out;
}

.rce-avatar-container.rce-citem-avatar-encircle-status {
    box-sizing: border-box;
    position: relative;
}

.rce-avatar-letter {
    margin-top: 5px;
    font-size: 20px;
    color: #FAFAFA;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rce-avatar-letter-background{
    height: 35px;
    width: 35px;
    border-radius: 20px;
}
.rce-dropdown-container {
    position: relative;
}
.rce-dropdown {
    min-width: 100%;
    box-sizing: border-box;
    padding: 8px 15px;
    background: #FAFAFA;
    border-radius: 5px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    -webkit-transform: scale(0);
            transform: scale(0);
    position: absolute;
    box-shadow: 0px 0px 5px 0px rgba(163, 163, 163, 1);
    -webkit-transform-origin: left top;
            transform-origin: left top;
    z-index: 99999;
}

.rce-dropdown-title {
    font-size: 14px;
    color: '#000';
    font-weight: 500;
}

.rce-dropdown.dropdown-show{
    -webkit-animation: dropdown-scaling 0.2s ease forwards;
            animation: dropdown-scaling 0.2s ease forwards;
    display: flex;
}

@-webkit-keyframes dropdown-scaling {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes dropdown-scaling {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.rce-dropdown.dropdown-hide{
    -webkit-animation: dropdown-reverse-scaling 0.2s ease forwards;
            animation: dropdown-reverse-scaling 0.2s ease forwards;
    display: flex;
}

@-webkit-keyframes dropdown-reverse-scaling {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        opacity: 0.5;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}

@keyframes dropdown-reverse-scaling {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        opacity: 0.5;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}

.rce-dropdown-open__nortwest {
    -webkit-transform-origin: left top;
            transform-origin: left top;
    left: 0;
    top: 100%;
    margin-top: 5px
}

.rce-dropdown-open__norteast {
    -webkit-transform-origin: right top;
            transform-origin: right top;
    right: 0;
    top: 100%;
    margin-top: 5px
}

.rce-dropdown-open__southwest {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    left: 0;
    bottom: 100%;
    margin-bottom: 5px
}

.rce-dropdown-open__southeast {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    right: 0;
    bottom: 100%;
    margin-bottom: 5px
}

.rce-dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rce-dropdown ul li {
    white-space: nowrap;
    color: #767676;
    padding: 8px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
    box-sizing: border-box;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.rce-dropdown ul li:last-child {
    border: none;
}

.rce-dropdown ul li:hover a {
    color: #3a6d8c;
}

.rce-dropdown.fade {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation: dropdown-fade 0.5s ease forwards;
            animation: dropdown-fade 0.5s ease forwards;
}

@-webkit-keyframes dropdown-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes dropdown-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.rce-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    padding: 8px;
    text-align: center;
    box-sizing: border-box;
    background: #3979aa;
    color: white;
    transition: all 0.15s ease;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border: none;
    outline: none;
    border: none;
    position: relative;
}

.rce-button-icon--container {
    display: flex;
    align-items: center;
}

.rce-button:hover {
    opacity: 0.8;
}

.rce-button:active {
    opacity: 0.6;
}

.rce-button.outline {
    background: rgba(0, 0, 0, 0) !important;
    border: 1px solid #3979aa;
    color: #3979aa;
}

.rce-button.outline:hover {
    opacity: 0.6;
}

.rce-button.outline:active {
    opacity: 0.3;
}

.rce-button.transparent {
    background: rgba(0, 0, 0, 0) !important;
}

.rce-button.transparent:hover {
    opacity: 0.6;
}

.rce-button.transparent:active {
    opacity: 0.3;
}

.rce-button-icon {
    position: relative;
    font-size: 18px;
    display: flex;
    padding: 0 3px;
}

.rce-button-badge{
    border-radius: 4px;
    padding: 4px;
    background: #f64b34;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -7px;
    top:-7px;
    font-size: 10px;
}

.rce-button.circle {
    min-width: 35px;
    min-height: 35px;
    border: 1px solid #3979aa;
    border-radius: 100%;
}
.rce-mbox-photo {
    margin-top: -3px;
    margin-right: -6px;
    margin-left: -6px;
}

.rce-mbox-photo .rce-mbox-text {
    padding: 5px 0px;
    max-width: 300px;
    margin: auto;
}

.rce-mbox-photo--img {
    position: relative;
    display: flex;
    overflow: hidden;
    justify-content: center;
    border-radius: 5px;
    max-height: 300px;
}

.rce-mbox-photo--img__block {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    border-radius: 5px;
    display: flex;
}

.rce-mbox-photo--img img {
    height: 100%;
    width: 100%;
    min-height: 100px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    background: rgba(159,173,183, 0.3);
    border-radius: 10px;
    /* background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
}

.rce-mbox-photo--img__block-item {
    margin: auto;
    cursor: pointer;
    width: 100px;
    height: 100px;
}

.rce-mbox-photo--download {
    color: #efe;
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    font-size: 3.2em;
    outline: none;
    border: 1px solid #eee;
    border-radius: 100%;
    height: 100px;
    width: 100px;
}

.rce-mbox-photo--download:hover {
    opacity: .7;
}

.rce-mbox-photo--download:active {
    opacity: .3;
}

.rce-mbox-photo--error {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    font-size: 70px;
    color: #eaeaea;
}
.rce-mbox-audio {
    padding-bottom: 10px;
    max-width: 300px;
}

audio:focus {
    outline: none;
}.rce-container-citem {
    flex-direction: column;
    display: block;
    overflow: hidden;
    min-width: 240px;
}

.rce-container-citem.rce-citem-status-encircle {
    position: relative;
}

.rce-citem {
    position: relative;
    background: white;
    display: flex;
    flex-direction: row;
    height: 72px;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    max-width: 100%;
    overflow: hidden;
    min-width: 240px;
}

.rce-citem:hover {
    background: #f9f9f9;
}

.rce-citem-avatar {
    position: relative;
    padding: 0 15px 0 13px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.rce-citem-status {
    width: 20px;
    height: 20px;
    bottom: 10px;
    right: 10px;
    position: absolute;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ccc;
}

.rce-citem-avatar.rce-citem-status-encircle .rce-citem-status {
    left:0;
    right:0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    margin: auto;
    border-radius: 100%;
}

.rce-citem-avatar img {
    width: 50px;
    height: 50px;
    border: none !important;
    background: #ccc;
    border-radius: 100%;
    overflow: hidden;
    font-size: 10px;
    text-align: center;
    line-height: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rce-citem-body {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    padding-right: 15px;
    border-bottom: 1px solid rgba(0,0,0,.05);
    overflow: hidden;
}

.rce-citem-body--top {
    display: flex;
}

.rce-citem-body--bottom {
    margin-top: 4px;
    display: flex;
}

.rce-citem-body--bottom-title, .rce-citem-body--top-title {
    flex: 1 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.rce-citem-body--top-title {
    font-size: 16px;
}

.rce-citem-body--bottom-title {
    color: #555;
    font-size: 15px;
}

.rce-citem-body--top-time {
    font-size: 12px;
    color: rgba(0,0,0,0.4)
}

.rce-citem-body--bottom-status {
    margin-left: 3px;
}

.rce-citem-body--bottom-status span {
    width: 18px;
    height: 18px;
    font-size: 12px;
    color: white;
    font-weight: bold;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 100%;
    background: red;
}
.rce-container-clist {
    display: block;
    overflow: auto;
}
.rce-container-mlist {
    position: relative;
    display: flex;
}

.rce-mlist {
    display: block;
    overflow: auto;
    position: relative;
    flex: 1 1;
}

.rce-mlist-down-button {
    position: absolute;
    right: 10px;
    bottom: 15px;
    width: 40px;
    height: 40px;
    background: #FAFAFA;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.05), 0 2px 5px 0 rgba(0,0,0,0.1);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    cursor: pointer;
    transition: 200ms;
}

.rce-mlist-down-button:hover {
    opacity: 0.7;
}

.rce-mlist-down-button--badge {
    position: absolute;
    right: -5px;
    top: -5px;
    background: red;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    font-size: 12px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #FAFAFA;
    font-weight: 700;
}
.rce-container-input {
    display: flex;
    min-width: 100%;
    box-sizing: border-box;
    flex-direction: row;
    background: #FAFAFA;
    align-items: center;
    border-radius: 5px;
}

.rce-input {
    flex: 1 1;
    height: 40px;
    padding: 0 5px;
    border: none;
    border-radius: 5px;
    color: #333;
    font-size: 14px;
    box-sizing: border-box;
    outline: none;
}

.rce-input-textarea {
    height: 37px;
    padding: 10px 5px;
    resize: none;
}

.rce-input-buttons {
    display: flex;
    flex-direction: row;
    margin: 5px;
}

.rce-input-buttons > * {
    display: flex;
    flex-direction: row;
}

.rce-input-buttons .rce-button:nth-child(even) {
    margin-left: 5px;
    margin-right: 5px;
}

.rce-input-buttons .rce-button:last-child {
    margin-right: 0;
}

.rce-left-padding {
    padding-left: 10px;
    padding-right: 0px !important;
}

.rce-right-padding {
    padding-right: 10px;
    padding-left: 0px !important;
}

.rce-input::-webkit-input-placeholder {
    color: #afafaf;
}

.rce-input:-ms-input-placeholder {
    color: #afafaf;
}

.rce-input::placeholder {
    color: #afafaf;
}
.rce-navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
}

.rce-navbar.light {
    background: #f4f4f4;
}

.rce-navbar.dark {
    background: #2f414c;
}

.rce-navbar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.rce-navbar-item > * {
    display: flex;
    flex-direction: row;
}

.rce-navbar-item > * > * {
    margin-left: 5px;
}.rce-sbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
    min-height: 100%;
}

.rce-sbar.light {
    background: #f4f4f4;
}

.rce-sbar.dark {
    background: #2f414c;
}

.rce-sbar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
}

.rce-sbar-item > * {
    display: flex;
    flex-direction: column;
}

.rce-sbar-item__center {
    margin: 15px 0;
}.rce-popup-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: rgba(255, 255, 255, 0.7);
    z-index: 9999999999;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.rce-popup {
    background: #FAFAFA;
    border-radius: 5px;
    padding: 0 10px 0 10px;
    width: 400px;
    min-height: 100px;
    box-shadow: 0px 0px 25px -2px rgba(79, 79, 79, 1);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    -webkit-animation: popup-scaling 0.4s ease forwards;
            animation: popup-scaling 0.4s ease forwards;
    box-sizing: border-box;
}

@-webkit-keyframes popup-scaling {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 0;
    }
    50% {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}

@keyframes popup-scaling {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 0;
    }
    50% {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}

.rce-popup-header {
    padding: 18px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

.rce-popup-content {
    padding: 8px;
    font-size: 14px;
    box-sizing: border-box;
}

.rce-popup-content * {
    margin: 0
}

.rce-popup-footer {
    padding: 18px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
}

.rce-popup-footer>* {
    margin-left: 5px;
}.rce-container-mtitem {
    flex-direction: column;
    display: block;
    overflow: hidden;
    min-width: 240px;
}

.rce-mtitem {
    position: relative;
    background: white;
    display: flex;
    flex-direction: column;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    max-width: 100%;
    overflow: hidden;
    min-width: 240px;
    border-bottom: 1px solid rgba(0,0,0,.05);
}

.rce-mtitem:hover {
    background: #fbfbfb;
}

.rce-mtitem-top {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
}

.rce-mtitem-subject {
    padding: 0 10px;
    margin-top: 5px;
    font-size: 15px;
    overflow: hidden;
    color: #333;
    max-height: 35px;
    text-overflow: ellipsis;
}

.rce-mtitem-body {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    display: flex;
    justify-content: center;
    padding: 0 10px;
    overflow: hidden;
    align-items: center;
}

.rce-mtitem-body--avatars {
    display: flex;
    flex: 1 1;
    overflow: hidden;
    opacity: 0.7;
}

.rce-mtitem-body--functions {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.rce-mtitem-footer {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.rce-mtitem-body--avatars .rce-avatar-container {
    margin-left: -10px;
    border: 2px solid #FAFAFA;
}

.rce-mtitem-body--avatars .rce-avatar-container:first-child {
    margin: 0;
}

.rce-mtitem-letter {
    color: #FAFAFA;
    background: #e48989;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rce-mtitem-button {
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FAFAFA;
    background: #5ba7c5;
    border-radius: 100%;
    padding: 3px;
    transition: 300ms;
}

.rce-mtitem-closable {
    font-size: 25px;
    color: #FAFAFA;
    background:#ff4a4f;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 3px;
    margin-right: 7px;
}

.rce-mtitem:hover .rce-mtitem-closable {
    display: flex;
    cursor: pointer;
}

.rce-mtitem-share {
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5ba7c5;
    margin: -10px 0;
    transition: 300ms;
    position: relative;
    left: -10px;
}

.rce-mtitem-button:hover,
.rce-mtitem-share:hover {
    opacity: 0.8;
    cursor: pointer;
}

.rce-mtitem-date {
    color: #9f9f9f;
    font-size: 13px;
}
.rce-container-mtlist {
    display: block;
    overflow: auto;
}

.react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  
  .react-autosuggest__section-container {
    border-top: 1px dashed #ccc;
  }
  
  .react-autosuggest__section-container--first {
    border-top: 0;
  }
  
  .react-autosuggest__section-title {
    padding: 10px 0 0 10px;
    font-size: 12px;
    color: #777;
  }
  
/* .rce-container-mbox {
    flex-direction: column;
    display: block;
    overflow-x: hidden;
    min-width: 300px;
}

.rce-mbox-forward {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    background: #fff;
    position: absolute;

    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px 0 rgba(164, 164, 164, 1);
    cursor: pointer;
    transition: all 0.3s ease;
    top: 0;
    bottom: 0;
    margin: auto;
}

.rce-mbox-forward-left {
    display: flex;
    opacity: 0;
    visibility: hidden;
    left: -50px;
}

.rce-mbox-forward-right {
    display: flex;
    opacity: 0;
    visibility: hidden;
    right: -50px;
}

.rce-mbox-reply-btn-left {
    display: flex;
    opacity: 0;
    visibility: hidden;
    left: -85px;
}

.rce-mbox-reply-btn-right {
    display: flex;
    opacity: 0;
    visibility: hidden;
    right: -85px;
}

.rce-container-mbox:hover .rce-mbox-forward-left {
    opacity: 1;
    visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-forward-right {
    opacity: 1;
    visibility: visible;
}

.rce-mbox-remove {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    background: #fff;
    position: absolute;
 
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px 0 rgba(164, 164, 164, 1);
    cursor: pointer;
    transition: all 0.3s ease;
    top: 0;
    bottom: 0;
    margin: auto;
}

.rce-mbox-remove-left {
    display: flex;
    opacity: 0;
    visibility: hidden;
    left: -120px;
}

.rce-mbox-remove-right {
    display: flex;
    opacity: 0;
    visibility: hidden;
    right: -120px;
}

.rce-container-mbox:hover .rce-mbox-remove-left {
    opacity: 1;
    visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-remove-right {
    opacity: 1;
    visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-reply-btn-left {
    opacity: 1;
    visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-reply-btn-right {
    opacity: 1;
    visibility: visible;
}

.rce-mbox {
    position: relative;
    background: white;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .2);
    border-top-left-radius: 0px;
    margin-left: 20px;
    margin-right: 5px;
    margin-top: 3px;
    flex-direction: column;
    margin-bottom: 3px;
    padding: 6px 9px 8px 9px;
    float: left;
    min-width: 140px;
}

.rce-mbox.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    animation-name: message-box-default-focus;
    animation-duration: 1s;
}

@-webkit-keyframes message-box-default-focus {
    from {background-color: #fff;}
    to {background-color: #dfdfdf;}
}

.rce-mbox-body {
    margin: 0;
    padding: 0;
    position: relative;
}

.rce-mbox.rce-mbox-right {
    float: right;
    margin-left: 5px;
    margin-right: 20px;
    border-top-right-radius: 0px;
    border-top-left-radius: 5px;
}

.rce-mbox.rce-mbox-right.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    animation-name: message-box-right-focus;
    animation-duration: 1s;
}

@-webkit-keyframes message-box-right-focus {
    from {background-color: #d4f1fb;}
    to {background-color: #b8dae6;}
}

.rce-mbox-text {
    font-size: 13.6px;
    word-break: break-word;
}

.rce-mbox-text-retracted {
    font-style: italic;
    user-select: none;
    display: flex;
    align-items: center;
}

.rce-mbox-text.rce-mbox-text-retracted svg {
    margin-right: 3px;
}

.rce-mbox-text-retracted.left {
    color: #555555b3 !important;
}

.rce-mbox-text-retracted.right {
    color: #efefefb3 !important;
}

.rce-mbox-text:after {
    content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0"
}

.rce-mbox-time {
    text-align: right;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    position: absolute;
    right: -4px;
    bottom: -5px;
}

.rce-mbox-time.non-copiable:before {
    content: attr(data-text);
}

.rce-mbox-time-block {
    
    right: 0;
    bottom: 0;
    left: 0;
    margin-right: -6px;
    margin-left: -6px;
    padding-top: 5px;
    padding-right: 3px;
    padding-bottom: 2px;
    background: linear-gradient(to top, rgba(0,0,0,0.33), transparent);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
}

.rce-mbox--clear-padding {
    padding-bottom: 3px;
}

.rce-mbox.rce-mbox--clear-notch {
    border-radius: 5px 5px 5px 5px !important;
}

.rce-mbox-right-notch {
    position: absolute;
    right: -14px;
    top: 0px;
    width: 15px;
    height: 15px;
    fill: white;
    filter: drop-shadow( 2px 0px 1px rgba(0, 0, 0, .2));
}

.rce-mbox-right-notch.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    animation-name: message-right-notch-focus;
    animation-duration: 1s;
}

@-webkit-keyframes message-right-notch-focus {
    from {fill: #d4f1fb;}
    to {fill: #b8dae6;}
}

.rce-mbox-left-notch {
    position: absolute;
    left: -14px;
    top: 0px;
    width: 15px;
    height: 15px;
    fill: white;
}

.rce-mbox-left-notch.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    animation-name: message-left-notch-focus;
    animation-duration: 1s;
}

@-webkit-keyframes message-left-notch-focus {
    from {fill: #fff;}
    to {fill: #dfdfdf;}
}

.rce-mbox-title {
    margin: 0;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 13px;
    color: #4f81a1;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.rce-mbox-title:hover {
    text-decoration: underline;
}

.rce-mbox-title--clear {
    margin-bottom: 5px;
}

.rce-mbox-status {
    margin-left: 3px;
    font-size: 15px;
}

.rce-mbox-title > .rce-avatar-container {
    margin-right: 5px;
}
 */


 .rce-mbox-video {
    margin-top: -3px;
    margin-right: -6px;
    margin-left: -6px;
    width: 100%;
}

.rce-mbox-video.padding-time {
    padding-bottom: 12px;
}

.rce-mbox-video .rce-mbox-text {
    padding: 5px 0px;
    max-width: 300px;
    margin: auto;
}

.rce-mbox-video--video {
    position: relative;
    display: flex;
    overflow: hidden;
    justify-content: center;
    border-radius: 5px;
    max-height: 500px;
   
}

.rce-mbox-video--video__block {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    border-radius: 5px;
    display: flex;
}

.rce-mbox-video--video img {
    height: 100%;
    min-height: 100px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  
}

.rce-mbox-video--video video {
    width: 100%;
    min-height: 100px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.rce-mbox-video--video__block-item {
    margin: auto;
    cursor: pointer;
    width: 100px;
    height: 100px;
}

.rce-mbox-video--download {
    color: #efe;
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    font-size: 3.2em;
    outline: none;
    border: 1px solid #eee;
    border-radius: 100%;
    height: 100px;
    width: 100px;
}

.rce-mbox-video--download:hover {
    opacity: .7;
}

.rce-mbox-video--download:active {
    opacity: .3;
}

.rce-mbox-video--error {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    font-size: 70px;
    color: #eaeaea;
}
.rce-container-mbox {
    flex-direction: column;
    display: block;
    overflow-x: hidden;
    min-width: 200px;
}
.rce-mbox-remove {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    background: #fff;
    position: absolute;
    /*display: none;*/
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px 0 rgba(164, 164, 164, 1);
    cursor: pointer;
    transition: all 0.3s ease;
    top: 0;
    bottom: 0;
    margin: auto;
}

.rce-mbox-remove-left {
    display: flex;
    opacity: 0;
    visibility: hidden;
    left: -120px;
}

.rce-mbox-remove-right {
    display: flex;
    opacity: 0;
    visibility: hidden;
    right: -120px;
}

.rce-container-mbox:hover .rce-mbox-remove-left {
    opacity: 1;
    visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-remove-right {
    opacity: 1;
    visibility: visible;
}
.rce-mbox-forward {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    background: #fff;
    position: absolute;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px 0 rgba(164, 164, 164, 1);
    cursor: pointer;
    transition: all 0.3s ease;
    top: 0;
    bottom: 0;
    margin: auto;
}

.rce-mbox-forward-left {
    display: flex;
    opacity: 0;
    visibility: hidden;
    left: -50px;
}

.rce-mbox-forward-right {
    display: flex;
    opacity: 0;
    visibility: hidden;
    right: -50px;
}

.rce-mbox-reply-btn-left {
    display: flex;
    opacity: 0;
    visibility: hidden;
    left: -85px;
}

.rce-mbox-reply-btn-right {
    display: flex;
    opacity: 0;
    visibility: hidden;
    right: -85px;
}

.rce-container-mbox:hover .rce-mbox-forward-left {
    opacity: 1;
    visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-forward-right {
    opacity: 1;
    visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-reply-btn-left {
    opacity: 1;
    visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-reply-btn-right {
    opacity: 1;
    visibility: visible;
}

.rce-mbox {
    position: relative;
    background: white;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .2);
    border-top-left-radius: 0px;
    margin-left: 20px;
    margin-right: 5px;
    margin-top: 3px;
    flex-direction: column;
    margin-bottom: 3px;
    padding: 6px 9px 8px 9px;
    float: left;
    min-width: 140px;
    max-width:85%;
}

.rce-mbox.message-focus {
    animation-iteration-count: 2;
    animation-name: message-box-default-focus;
    animation-duration: 1s;

    -webkit-animation-name: message-box-default-focus;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
}

@-webkit-keyframes message-box-default-focus {
    from {background-color: #fff;}
    to {background-color: #808080;}
}
@keyframes message-box-default-focus {
    from {background-color: #fff;}
    to {background-color: #808080;}
}
.rce-mbox-body {
    margin: 0;
    padding: 0;
    position: relative;
}

.rce-mbox.rce-mbox-right {
    float: right;
    margin-left: 5px;
    margin-right: 20px;
    border-top-right-radius: 0px;
    border-top-left-radius: 5px;
    background:#d4f1fb;
  
}


.rce-mbox.rce-mbox-right.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    -webkit-animation-name: message-box-right-focus;
            animation-name: message-box-right-focus;
    animation-duration: 1s;
    
}

@-webkit-keyframes message-box-right-focus {
    from {background-color: #d4f1fb;}
    to {background-color: #808080;}
}
@keyframes message-box-right-focus {
    from {background-color: #d4f1fb;}
    to {background-color: #808080;}
}
.rce-mbox-text {
    font-size: 16px;
    word-break: break-word;
white-space: pre-wrap;
width:100%;
margin-bottom:5px;
}

.rce-mbox-text:after {
    content: "\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0"
}

.rce-mbox-time {
    text-align: right;
    color: #808080;
    font-size: 10px;
    position: absolute;
    right: -4px;
    bottom: -11px;
}

.rce-mbox-time.non-copiable:before {
    content: attr(data-text);
}

.rce-mbox-time-block {
    right: 0;
    bottom: 0;
    left: 0;
    margin-right: -6px;
    margin-left: -6px;
    padding-top: 5px;
    padding-right: 3px;
    padding-bottom: 2px;
    background: linear-gradient(to top, rgba(0,0,0,0.33), transparent);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #FAFAFA;
}

.rce-mbox--clear-padding {
    padding-bottom: 3px;
}

.rce-mbox.rce-mbox--clear-notch {
    border-radius: 5px 5px 5px 5px !important;
}

.rce-mbox-right-notch {
    position: absolute;
    right: -14px;
    top: 0px;
    width: 15px;
    height: 15px;
    fill: #d4f1fb;
    -webkit-filter: drop-shadow( 2px 0px 1px rgba(0, 0, 0, .2));
            filter: drop-shadow( 2px 0px 1px rgba(0, 0, 0, .2));
}

.rce-mbox-right-notch.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    animation-name: message-right-notch-focus;
    -webkit-animation-name: message-right-notch-focus;
    animation-duration: 1s;
}

@-webkit-keyframes message-right-notch-focus {
    from {fill: #d4f1fb;}
    to {fill: #808080;}
}
@keyframes message-right-notch-focus {
    from {fill: #d4f1fb;}
    to {fill: #808080;}
}
.rce-mbox-left-notch {
    position: absolute;
    left: -14px;
    top: 0px;
    width: 15px;
    height: 15px;
    fill: white;
}

.rce-mbox-left-notch.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    animation-name: message-left-notch-focus;
    -webkit-animation-name: message-left-notch-focus;
    animation-duration: 1s;
}

@-webkit-keyframes message-left-notch-focus {
    from {fill: #fff;}
    to {fill: #808080;}
}
@keyframes message-left-notch-focus {
    from {fill: #fff;}
    to {fill: #808080;}
}

.rce-mbox-title {
    margin: 0;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 15px;
    color: #4f81a1;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex;
    align-items: center;
}

.rce-mbox-title:hover {
}

.rce-mbox-title--clear {
    margin-bottom: 5px;
}

.rce-mbox-status {
    margin-left: 3px;
    font-size: 15px;
}

.rce-mbox-title > .rce-avatar-container {
    margin-right: 5px;
}
.rce-mbox-file {
    margin-bottom: 3px;
}

.rce-mbox-file > button {
    background: #e9e9e9;
    display: flex;
    border-radius: 5px;
    margin-top: -3px;
    margin-right: -6px;
    margin-left: -6px;
    align-items: center;
    min-height: 52px;
    max-width: 500px;
    padding: 5px 0;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    outline: none;
    border:none;
}

.rce-mbox-file > button > * {
    padding: 0px 10px;
}

.rce-mbox-file--icon {
    font-size: 30px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.rce-mbox-file--size {
    font-size: 10px;
    margin-top: 3px;
    max-width: 52px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rce-mbox-file--text {
    font-size: 13.6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rce-mbox-file--buttons {
    font-size: 30px;
    align-items: center;
    display: flex;
}
.rce-mbox-file--buttons .rce-error-button {
    display: inherit;
}

.rce-mbox-file--loading {
    font-size: 15px;
    width: 40px;
    height: 40px;
}
.rce-container-smsg {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rce-smsg {
    position: relative;
    background: white;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,.2);
    display: flex;
    flex-direction: column;
    margin: 5px 0px;
    padding: 6px 9px 8px 9px;
    float: left;
    max-width: 70%;
    align-items: center;
    justify-content: center;
}

.rce-smsg-text {
    text-align: center;
    display: inline-block;
    font-size: 15px;
    white-space: pre-wrap;
}
.rce-mbox-location {
    position: relative;
    width: 250px;
    height: 150px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -3px;
    margin-right: -6px;
    margin-left: -6px;
    border-radius: 5px;
}

.rce-mbox-location-img {
    width: 100%;
}

.rce-mbox-location-text {
    padding: 5px 0;
    width: 250px;
    margin-left: -6px;
    margin-right: -6px;
}
.rce-mbox-spotify {
    margin-top: -2px;
    overflow: hidden;
    margin-right: -6px;
    margin-left: -6px;
    display: flex;
    border-radius: 5px;
}
.rce-mbox-reply {
    position: relative;
    overflow: hidden;
    display: flex;
    margin-top: -3px;
    margin-bottom: 6px;
    margin-right: -6px;
    margin-left: -6px;
    border-radius: 5px;
    background: #ececec;
    padding: 0 5px;
    padding-right: 0;
    font-size: 12px;
    cursor: pointer;
    transition: 200ms;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.rce-mbox-reply.rce-mbox-reply-border {
    border-left: 5px solid;
}

.rce-mbox-reply:hover {
    opacity: 0.85;
}

.rce-mbox-reply-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3px 0;
    flex: 1 1;
}

.rce-mbox-reply-owner {
    font-size: 13px;

}

.rce-mbox-reply-message {
    color: #5a5a5a;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;

}

.rce-mbox-reply-right {
    width: 40px;
    height: 40px;
}

.rce-mbox-reply-right img {
    width: 100%;
}

.rce-mbox-reply-text {
    padding: 5px 0;
    width: 250px;
    margin-left: -6px;
    margin-right: -6px;
}
.rce-mbox-mtmg {
    display: flex;
    justify-content: center;
    align-content: center;
    padding-bottom: 13px;
    min-width: 425px;
    max-width: 425px;
}

.rce-mtmg {
    width: 100%;
    position: relative;
    background: #f5f5f5;
    display: flex;
    flex-direction: column;
    margin: 5px 0px;
    float: left;
    border-radius: 2px;
}

.rce-mtmg-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 300px;
}

.rce-mtmg-subject {
    text-align: start;
    display: inline-block;
    font-size: 15px;
    padding: 5px 9px;
}

.rce-mtmg-toogleItem {
    width: 100%;
    height: 100%;
}

.rce-mtmg-body {
    height: 50px;
    background: #6264a7;
    color: white;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
}

.rce-mtmg-body:hover {
    opacity: 0.9;
}

.rce-mtmg-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 10px;
}

.rce-mtmg-item > svg {
    width: 23px;
    height: 23px;
}

.rce-mtmg-content {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
}

.rce-mtmg-date {
    color: #cecece;
    font-size: 13px;
}

.rce-mtmg-body-bottom {
    display: flex;
    flex-direction: row;
    padding: 9px;
    color: #6264a7;
    cursor: pointer;
    font-size: 13px;
}

.rce-mtmg-bottom--tptitle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rce-mtmg-bottom--tptitle > svg,
.rce-mtmg-body-bottom--bttitle > svg {
    color: #6264a7a1;
}

.rce-mtmg-toogleContent {
    display: none;
    height: auto;
    min-height: 60px;
    flex-direction: column;
}

.rce-mtmg-toogleContent--click {
    display: flex;
}

.rce-mtmg-right-icon {
    right: 10px;
    cursor: pointer;
    height: 100%;
    background: transparent !important;
}

.rce-mtmg-body .rce-dropdown-container {
    height: 100%;
}

.rce-mtmg-right-icon > svg {
    width: 23px;
    height: 23px;
}

.rce-mitem {
    display: flex;
    padding: 10px 8px;
}

.rce-mitem:hover,
.rce-mitem-event:hover {
    background: #ececec;
}

.rce-mitem-event {
    -webkit-user-select:  none;
        -ms-user-select:  none;
            user-select:  none;
}

.rce-mitem-body {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.rce-mitem-body-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.rce-mitem-body--top {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.rce-mitem-body--top-title {
    font-size: 15px;
    color: #6264a7;
    padding: 0px 15px 0 0;
    text-transform: capitalize;
    font-weight: 600;
}

.rce-mitem-body--top-title:hover {
    cursor: pointer;
    text-decoration: underline;
}

.rce-mitem-body--bottom-title {
    color: #252525;
    font-size: 13px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.rce-mitem-body--top-time {
    font-size: 12px;
    color: rgba(0,0,0,0.4);
}

.rce-mitem-bottom-body {
    padding: 10px 8px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.rce-mitem-body.avatar {
    padding: 8px 10px 0 0;
}

.rce-mitem.avatar {
    padding: 8px 5px 0 0;
}

.rce-mitem.no-avatar {
    padding: 8px 10px 0 0;
}

.rce-mitem.no-avatar > svg {
    width: 19px;
    height: 19px;
}

.rce-mitem.avatar img {
    width: 22px;
    height: 22px;
    border: none !important;
    background: #ccc;
    border-radius: 100%;
}

.rce-mitem-body.avatar > svg {
    width: 19px;
    height: 19px;
}

.rce-mitem-bottom-body-top {
    display: flex;
    flex-direction: column;
}

.rce-mitem-bottom-body-top-title > svg {
    padding: 0 7px 0 0;
}

.rce-mitem-avatar-content {
    position: absolute;
    right: 10px;
    display: flex;
}

.rce-mitem-avatar {
    padding: 0 3px 0 0;
    display: flex;
}

.rce-mitem-tooltip {
    display: inline;
    position: relative;
}

.rce-mitem-tooltip-text {
    margin: 5px
}

.rce-mitem-tooltip-text:after{
    content: "";
    left: 15%;
    top: 29px;
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 11px solid #444;
    opacity: 0;
    transition: opacity 0.8s linear 0.2s;
  }

.rce-mitem-tooltip[tooltip]:after {
    display: flex;
    justify-content: center;
    background: #444;
    border-radius: 8px;
    color: #fff;
    content: attr(tooltip);
    font-size: 14px;
    padding: 5px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.8s linear 0.2s;
    min-width: 415px;
    max-width: 415px;
    top: 40px;
    right: -13px;
    z-index: 1;
}

.rce-mitem-tooltip-text:hover:after {
    opacity: 1;
    transition: opacity 0.8s linear;
}

.rce-mitem-tooltip[tooltip]:hover:after {
    opacity: 1;
    transition: opacity 0.8s linear  0.1s;
}

.rce-mitem-tooltip[tooltip]:hover .rce-mitem-tooltip-text:after {
    opacity: 1;
}

.rce-mitem-length {
    color: #fff;
    font-size: 14px;
    background: #e48989;
    display: flex;
    align-items: center;
    text-align: center;
    width: 25px;
    height: 25px;
    display: flex;
    border-radius: 50%;
}

.rce-mitem-avatar img {
    width: 50px;
    height: 50px;
    border: none !important;
    background: #ccc;
    border-radius: 100%;
}

.rce-mtmg-call-record {
    width: 350px;
    height: 85%;
    background: #eaeaea;
    margin-top: 11px;
}

.rce-mtmg-call-body {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    position: relative;
}

.rce-mtmg-call-avatars {
    width: 140px !important;
    height: 100px !important;
    position: relative;
}

.rce-mtmg-call-avatars img {
    width: 100% !important;
    height: 100% !important;
    background: #ccc;
    cursor: pointer;
}

.rce-mtmg-call-body-title {
    display: flex;
    position: relative;
    flex-direction: column;
    top: 30px;
    left: 15px;
}

.rce-mtmg-call-body-title > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 185px;
}

.rce-mtmg-call-body-bottom {
    color: #505050;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 185px;
    font-size: 12px;
}

.rce-mtmg-record-time {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 13px;
    background: #000000cf;
    color: white;
    padding: 4px;
    border-radius: 5px;
}
.rce-avatar-container {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rce-avatar-container .rce-avatar {
    width: 100%;
    height: 100%;
}

.rce-avatar-container.flexible .rce-avatar {
    height: auto !important;
    width: 100% !important;
    border-radius: unset !important;
    overflow: unset !important;
}

.rce-avatar-container.default{
    width: 25px;
    height: 25px;
}

.rce-avatar-container.rounded{
    border-radius: 5px;
}

.rce-avatar-container.circle{
    border-radius: 100%;
}

.rce-avatar-container.xsmall{
    width: 30px;
    height: 30px;
}

.rce-avatar-container.small{
    width: 35px;
    height: 35px;
}

.rce-avatar-container.medium{
    width: 40px;
    height: 40px;
}

.rce-avatar-container.large{
    width: 45px;
    height: 45px;
}

.rce-avatar-container.xlarge{
    width: 55px;
    height: 55px;
}

@-webkit-keyframes avatarLazy {
    0% {
        opacity:1;
    }
    50% {
        opacity:.5;
    }
    100% {
        opacity:1;
    }
}

@keyframes avatarLazy {
    0% {
        opacity:1;
    }
    50% {
        opacity:.5;
    }
    100% {
        opacity:1;
    }
}

.rce-avatar-lazy {
    -webkit-animation: avatarLazy normal 2s infinite ease-in-out;
            animation: avatarLazy normal 2s infinite ease-in-out;
}

.rce-avatar-container.rce-citem-avatar-encircle-status {
    box-sizing: border-box;
    position: relative;
}

.rce-avatar-letter {
    margin-top: 5px;
    font-size: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rce-avatar-letter-background{
    height: 35px;
    width: 35px;
    border-radius: 20px;
}
.rce-dropdown-container {
    position: relative;
}
.rce-dropdown {
    min-width: 100%;
    box-sizing: border-box;
    padding: 8px 15px;
    background: #fff;
    border-radius: 5px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    -webkit-transform: scale(0);
            transform: scale(0);
    position: absolute;
    box-shadow: 0px 0px 5px 0px rgba(163, 163, 163, 1);
    -webkit-transform-origin: left top;
            transform-origin: left top;
    z-index: 99999;
}

.rce-dropdown-title {
    font-size: 14px;
    color: '#000';
    font-weight: 500;
}

.rce-dropdown.dropdown-show{
    -webkit-animation: dropdown-scaling 0.2s ease forwards;
            animation: dropdown-scaling 0.2s ease forwards;
    display: flex;
}

@-webkit-keyframes dropdown-scaling {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes dropdown-scaling {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.rce-dropdown.dropdown-hide{
    -webkit-animation: dropdown-reverse-scaling 0.2s ease forwards;
            animation: dropdown-reverse-scaling 0.2s ease forwards;
    display: flex;
}

@-webkit-keyframes dropdown-reverse-scaling {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        opacity: 0.5;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}

@keyframes dropdown-reverse-scaling {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        opacity: 0.5;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}

.rce-dropdown-open__nortwest {
    -webkit-transform-origin: left top;
            transform-origin: left top;
    left: 0;
    top: 100%;
    margin-top: 5px
}

.rce-dropdown-open__norteast {
    -webkit-transform-origin: right top;
            transform-origin: right top;
    right: 0;
    top: 100%;
    margin-top: 5px
}

.rce-dropdown-open__southwest {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    left: 0;
    bottom: 100%;
    margin-bottom: 5px
}

.rce-dropdown-open__southeast {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    right: 0;
    bottom: 100%;
    margin-bottom: 5px
}

.rce-dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rce-dropdown ul li {
    white-space: nowrap;
    color: #767676;
    padding: 8px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
    box-sizing: border-box;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.rce-dropdown ul li:last-child {
    border: none;
}

.rce-dropdown ul li:hover a {
    color: #3a6d8c;
}

.rce-dropdown.fade {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation: dropdown-fade 0.5s ease forwards;
            animation: dropdown-fade 0.5s ease forwards;
}

@-webkit-keyframes dropdown-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes dropdown-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.rce-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    padding: 8px;
    text-align: center;
    box-sizing: border-box;
    background: #3979aa;
    color: white;
    transition: all 0.15s ease;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border: none;
    outline: none;
    border: none;
    position: relative;
}

.rce-button-icon--container {
    display: flex;
    align-items: center;
}

.rce-button:hover {
    opacity: 0.8;
}

.rce-button:active {
    opacity: 0.6;
}

.rce-button.outline {
    background: rgba(0, 0, 0, 0) !important;
    border: 1px solid #3979aa;
    color: #3979aa;
}

.rce-button.outline:hover {
    opacity: 0.6;
}

.rce-button.outline:active {
    opacity: 0.3;
}

.rce-button.transparent {
    background: rgba(0, 0, 0, 0) !important;
}

.rce-button.transparent:hover {
    opacity: 0.6;
}

.rce-button.transparent:active {
    opacity: 0.3;
}

.rce-button-icon {
    position: relative;
    font-size: 18px;
    display: flex;
    padding: 0 3px;
}

.rce-button-badge{
    border-radius: 4px;
    padding: 4px;
    background: #f64b34;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -7px;
    top:-7px;
    font-size: 10px;
}

.rce-button.circle {
    min-width: 35px;
    min-height: 35px;
    border: 1px solid #3979aa;
    border-radius: 100%;
}
.rce-mbox-photo {
    margin-top: -3px;
    margin-right: -6px;
    margin-left: -6px;
}

.rce-mbox-photo .rce-mbox-text {
    padding: 5px 0px;
    max-width: 300px;
    margin: auto;
}

.rce-mbox-photo--img {
    position: relative;
    display: flex;
    overflow: hidden;
    justify-content: center;
    border-radius: 5px;
    max-height: 300px;
}

.rce-mbox-photo--img__block {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    border-radius: 5px;
    display: flex;
}

.rce-mbox-photo--img img {
    height: 100%;
    width: 100%;
    min-height: 100px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    background: rgba(159,173,183, 0.3);
    border-radius: 10px;
    
}

.rce-mbox-photo--img__block-item {
    margin: auto;
    cursor: pointer;
    width: 100px;
    height: 100px;
}

.rce-mbox-photo--download {
    color: #efe;
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    font-size: 3.2em;
    outline: none;
    border: 1px solid #eee;
    border-radius: 100%;
    height: 100px;
    width: 100px;
}

.rce-mbox-photo--download:hover {
    opacity: .7;
}

.rce-mbox-photo--download:active {
    opacity: .3;
}

.rce-mbox-photo--error {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    font-size: 70px;
    color: #eaeaea;
}
.rce-mbox-audio {
    padding-bottom: 10px;
    max-width: 300px;
}

audio:focus {
    outline: none;
}.rce-container-citem {
    flex-direction: column;
    display: block;
    overflow: hidden;
    min-width: 240px;
}

.rce-container-citem.rce-citem-status-encircle {
    position: relative;
}

.rce-citem {
    position: relative;
    background: white;
    display: flex;
    flex-direction: row;
    height: 72px;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    max-width: 100%;
    overflow: hidden;
    min-width: 240px;
}

.rce-citem:hover {
    background: #f9f9f9;
}

.rce-citem-avatar {
    position: relative;
    padding: 0 15px 0 13px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.rce-citem-status {
    width: 20px;
    height: 20px;
    bottom: 10px;
    right: 10px;
    position: absolute;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ccc;
}

.rce-citem-avatar.rce-citem-status-encircle .rce-citem-status {
    left:0;
    right:0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    margin: auto;
    border-radius: 100%;
}

.rce-citem-avatar img {
    width: 50px;
    height: 50px;
    border: none !important;
    background: #ccc;
    border-radius: 100%;
    overflow: hidden;
    font-size: 10px;
    text-align: center;
    line-height: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rce-citem-body {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    padding-right: 15px;
    border-bottom: 1px solid rgba(0,0,0,.05);
    overflow: hidden;
}

.rce-citem-body--top {
    display: flex;
}

.rce-citem-body--bottom {
    margin-top: 4px;
    display: flex;
}

.rce-citem-body--bottom-title, .rce-citem-body--top-title {
    flex: 1 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.rce-citem-body--top-title {
    font-size: 16px;
}

.rce-citem-body--bottom-title {
    color: #555;
    font-size: 15px;
}

.rce-citem-body--top-time {
    font-size: 12px;
    color: rgba(0,0,0,0.4)
}

.rce-citem-body--bottom-status {
    margin-left: 3px;
}

.rce-citem-body--bottom-status span {
    width: 18px;
    height: 18px;
    font-size: 12px;
    color: white;
    font-weight: bold;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 100%;
    background: red;
}
.rce-container-clist {
    display: block;
    overflow: auto;
}
.rce-container-mlist {
    position: relative;
    display: flex;
}

.rce-mlist {
    display: block;
    overflow: auto;
    position: relative;
    flex: 1 1;
}

.rce-mlist-down-button {
    position: absolute;
    right: 10px;
    bottom: 15px;
    width: 40px;
    height: 40px;
    background: #fff;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.05), 0 2px 5px 0 rgba(0,0,0,0.1);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    cursor: pointer;
    transition: 200ms;
}

.rce-mlist-down-button:hover {
    opacity: 0.7;
}

.rce-mlist-down-button--badge {
    position: absolute;
    right: -5px;
    top: -5px;
    background: red;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    font-size: 12px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 700;
}
.rce-container-input {
    display: flex;
    min-width: 100%;
    box-sizing: border-box;
    flex-direction: row;
    background: #fff;
    align-items: center;
    border-radius: 5px;
}

.rce-input {
    flex: 1 1;
    height: 40px;
    padding: 0 5px;
    border: none;
    border-radius: 5px;
    color: #333;
    font-size: 14px;
    box-sizing: border-box;
    outline: none;
}

.rce-input-textarea {
    height: 37px;
    padding: 10px 5px;
    resize: none;
}

.rce-input-buttons {
    display: flex;
    flex-direction: row;
    margin: 5px;
}

.rce-input-buttons > * {
    display: flex;
    flex-direction: row;
}

.rce-input-buttons .rce-button:nth-child(even) {
    margin-left: 5px;
    margin-right: 5px;
}

.rce-input-buttons .rce-button:last-child {
    margin-right: 0;
}

.rce-left-padding {
    padding-left: 10px;
    padding-right: 0px !important;
}

.rce-right-padding {
    padding-right: 10px;
    padding-left: 0px !important;
}

.rce-input::-webkit-input-placeholder {
    color: #afafaf;
}

.rce-input:-ms-input-placeholder {
    color: #afafaf;
}

.rce-input::placeholder {
    color: #afafaf;
}
.rce-navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
}

.rce-navbar.light {
    background: #f4f4f4;
}

.rce-navbar.dark {
    background: #2f414c;
}

.rce-navbar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.rce-navbar-item > * {
    display: flex;
    flex-direction: row;
}

.rce-navbar-item > * > * {
    margin-left: 5px;
}.rce-sbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
    min-height: 100%;
}

.rce-sbar.light {
    background: #f4f4f4;
}

.rce-sbar.dark {
    background: #2f414c;
}

.rce-sbar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
}

.rce-sbar-item > * {
    display: flex;
    flex-direction: column;
}

.rce-sbar-item__center {
    margin: 15px 0;
}.rce-popup-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: rgba(255, 255, 255, 0.7);
    z-index: 9999999999;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.rce-popup {
    background: #fff;
    border-radius: 5px;
    padding: 0 10px 0 10px;
    width: 400px;
    min-height: 100px;
    box-shadow: 0px 0px 25px -2px rgba(79, 79, 79, 1);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    -webkit-animation: popup-scaling 0.4s ease forwards;
            animation: popup-scaling 0.4s ease forwards;
    box-sizing: border-box;
}

@-webkit-keyframes popup-scaling {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 0;
    }
    50% {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}

@keyframes popup-scaling {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 0;
    }
    50% {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}

.rce-popup-header {
    padding: 18px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

.rce-popup-content {
    padding: 8px;
    font-size: 14px;
    box-sizing: border-box;
}

.rce-popup-content * {
    margin: 0
}

.rce-popup-footer {
    padding: 18px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
}

.rce-popup-footer>* {
    margin-left: 5px;
}.rce-container-mtitem {
    flex-direction: column;
    display: block;
    overflow: hidden;
    min-width: 240px;
}

.rce-mtitem {
    position: relative;
    background: white;
    display: flex;
    flex-direction: column;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    max-width: 100%;
    overflow: hidden;
    min-width: 240px;
    border-bottom: 1px solid rgba(0,0,0,.05);
}

.rce-mtitem:hover {
    background: #fbfbfb;
}

.rce-mtitem-top {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
}

.rce-mtitem-subject {
    padding: 0 10px;
    margin-top: 5px;
    font-size: 15px;
    overflow: hidden;
    color: #333;
    max-height: 35px;
    text-overflow: ellipsis;
}

.rce-mtitem-body {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    display: flex;
    justify-content: center;
    padding: 0 10px;
    overflow: hidden;
    align-items: center;
}
.rce-mbox-text-retracted {
    font-style: italic;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex;
    align-items: center;
}

.rce-mbox-text.rce-mbox-text-retracted svg {
    margin-right: 3px;
}

.rce-mbox-text-retracted.left {
    color: #555555b3 !important;
}

.rce-mbox-text-retracted.right {
    color: #efefefb3 !important;
}
.rce-mtitem-body--avatars {
    display: flex;
    flex: 1 1;
    overflow: hidden;
    opacity: 0.7;
}

.rce-mtitem-body--functions {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.rce-mtitem-footer {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.rce-mtitem-body--avatars .rce-avatar-container {
    margin-left: -10px;
    border: 2px solid #fff;
}

.rce-mtitem-body--avatars .rce-avatar-container:first-child {
    margin: 0;
}

.rce-mtitem-letter {
    color: #fff;
    background: #e48989;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rce-mtitem-button {
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #5ba7c5;
    border-radius: 100%;
    padding: 3px;
    transition: 300ms;
}

.rce-mtitem-closable {
    font-size: 25px;
    color: #fff;
    background:#ff4a4f;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 3px;
    margin-right: 7px;
}

.rce-mtitem:hover .rce-mtitem-closable {
    display: flex;
    cursor: pointer;
}

.rce-mtitem-share {
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5ba7c5;
    margin: -10px 0;
    transition: 300ms;
    position: relative;
    left: -10px;
}

.rce-mtitem-button:hover,
.rce-mtitem-share:hover {
    opacity: 0.8;
    cursor: pointer;
}

.rce-mtitem-date {
    color: #9f9f9f;
    font-size: 13px;
}
.rce-container-mtlist {
    display: block;
    overflow: auto;
}

 
/*** src/styles.css ***/
.input-range__slider {
  -webkit-appearance: none;
          appearance: none;
  background: #00FF7F;
  border: 1px solid #00FF7F;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  width: 1rem; }
  .input-range__slider:active {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2); }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    -webkit-transform: none;
            transform: none; }

.input-range__slider-container {
  transition: left 0.3s ease-out; }

.input-range__label {
  color: #aaaaaa;
  font-family: "Helvetica Neue", san-serif;
  font-size: 0.8rem;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  white-space: nowrap; }

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute;
  display: none;}

.input-range__label--min {
  left: 0; }

.input-range__label--max {
  right: 0; }

.input-range__label--value {
  position: absolute;
  top: -1.8rem; }

.input-range__label-container {
  left: -50%;
  position: relative;
  display: none;}
  .input-range__label--max .input-range__label-container {
    left: 50%; }

.input-range__track {
  background: #9fadb7;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out; }
  .input-range--disabled .input-range__track {
    background: #9fadb7; }

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%; }

.input-range__track--active {
  background: #00FF7F; }

.input-range {
  height: 1rem;
  position: relative;
  width: 100%; }

/*# sourceMappingURL=react-input-range.css.map*/  
* {
    margin:0;
    padding:0;
    outline:0;
    box-sizing:border-box;
}
body{
    font-family: 'Source Sans Pro', sans-serif;
    background: #29454D;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    overflow-y: hidden;
}
html,body, #root {
    height: 100%;
}
input,button{
    font-family: 'Source Sans Pro', sans-serif;
}
button{
    cursor: pointer;
}

