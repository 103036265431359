@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

* {
    margin:0;
    padding:0;
    outline:0;
    box-sizing:border-box;
}
body{
    font-family: 'Source Sans Pro', sans-serif;
    background: #29454D;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    overflow-y: hidden;
}
html,body, #root {
    height: 100%;
}
input,button{
    font-family: 'Source Sans Pro', sans-serif;
}
button{
    cursor: pointer;
}
