@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?57125366');
  src: url('../font/fontello.eot?57125366#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?57125366') format('woff2'),
       url('../font/fontello.woff?57125366') format('woff'),
       url('../font/fontello.ttf?57125366') format('truetype'),
       url('../font/fontello.svg?57125366#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?57125366#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-atendimentoaberto1:before { content: '\e800'; } /* '' */
.icon-add:before { content: '\e801'; } /* '' */
.icon-dashboardoff:before { content: '\e802'; } /* '' */
.icon-pause:before { content: '\e803'; } /* '' */
.icon-senha:before { content: '\e804'; } /* '' */
.icon-user:before { content: '\e805'; } /* '' */
.icon-feito:before { content: '\e806'; } /* '' */
.icon-empresa:before { content: '\e807'; } /* '' */
.icon-atendimentoss:before { content: '\e808'; } /* '' */
.icon-menu2:before { content: '\e809'; } /* '' */
.icon-facebook-logo:before { content: '\e80a'; } /* '' */
.icon-vip:before { content: '\e80b'; } /* '' */
.icon-setavoltar:before { content: '\e80c'; } /* '' */
.icon-email:before { content: '\e80d'; } /* '' */
.icon-agendado:before { content: '\e80e'; } /* '' */
.icon-clerk:before { content: '\e80f'; } /* '' */
.icon-loading:before { content: '\e810'; } /* '' */
.icon-all:before { content: '\e811'; } /* '' */
.icon-d:before { content: '\e812'; } /* '' */
.icon-microphone:before { content: '\e813'; } /* '' */
.icon-down-arrow:before { content: '\e814'; } /* '' */
.icon-clip:before { content: '\e815'; } /* '' */
.icon-send:before { content: '\e816'; } /* '' */
.icon-new-user:before { content: '\e817'; } /* '' */
.icon-new-user3:before { content: '\e818'; } /* '' */
.icon-new-user2:before { content: '\e819'; } /* '' */
.icon-menu3:before { content: '\e81a'; } /* '' */
.icon-loupe:before { content: '\e81b'; } /* '' */
.icon-search:before { content: '\e81c'; } /* '' */
.icon-house:before { content: '\e81d'; } /* '' */
.icon-man-user:before { content: '\e81e'; } /* '' */
.icon-new-setor2:before { content: '\e81f'; } /* '' */
.icon-new-setor1:before { content: '\e820'; } /* '' */
.icon-multiple-users-silhouette:before { content: '\e821'; } /* '' */
.icon-warning:before { content: '\e822'; } /* '' */
.icon-favorite-heart-button:before { content: '\e823'; } /* '' */
.icon-icon:before { content: '\e824'; } /* '' */
.icon-block:before { content: '\e825'; } /* '' */
.icon-iconwpp:before { content: '\e826'; } /* '' */
.icon-settings:before { content: '\e830'; } /* '' */
.icon-settings-gears:before { content: '\e831'; } /* '' */
.icon-share:before { content: '\e832'; } /* '' */
.icon-instagram-logo:before { content: '\e833'; } /* '' */
.icon-envelope:before { content: '\e834'; } /* '' */
.icon-sair:before { content: '\e835'; } /* '' */
.icon-configuracao:before { content: '\e836'; } /* '' */
.icon-sincronizar:before { content: '\e837'; } /* '' */
.icon-configuracao2:before { content: '\e838'; } /* '' */
.icon-headset:before { content: '\e839'; } /* '' */
.icon-atendimentos:before { content: '\e83a'; } /* '' */
.icon-logout:before { content: '\e83b'; } /* '' */
.icon-close3:before { content: '\e83c'; } /* '' */
.icon-close:before { content: '\e83d'; } /* '' */
.icon-add2:before { content: '\e83e'; } /* '' */
.icon-cancel:before { content: '\e83f'; } /* '' */
.icon-comvisao:before { content: '\e840'; } /* '' */
.icon-semvisao:before { content: '\e841'; } /* '' */
.icon-box:before { content: '\e842'; } /* '' */
.icon-seminternet:before { content: '\e843'; } /* '' */
.icon-editable-speech-bubble:before { content: '\e844'; } /* '' */
.icon-comment:before { content: '\e845'; } /* '' */
.icon-comment2:before { content: '\e846'; } /* '' */
.icon-edit:before { content: '\e847'; } /* '' */
.icon-tempoespera:before { content: '\e848'; } /* '' */
.icon-atender:before { content: '\e849'; } /* '' */
.icon-vencimento:before { content: '\e84a'; } /* '' */
.icon-prioridade2:before { content: '\e84b'; } /* '' */
.icon-tempoespera2:before { content: '\e84c'; } /* '' */
.icon-angle-arrow-down:before { content: '\e84d'; } /* '' */
.icon-check:before { content: '\e84e'; } /* '' */
.icon-download:before { content: '\e84f'; } /* '' */
.icon-editar:before { content: '\e850'; } /* '' */
.icon-clip2:before { content: '\e851'; } /* '' */
.icon-calendar:before { content: '\e852'; } /* '' */
.icon-notification:before { content: '\e853'; } /* '' */
.icon-interrogacao:before { content: '\e854'; } /* '' */
.icon-chamadoatendimento:before { content: '\e855'; } /* '' */
.icon-chamadocancelado:before { content: '\e856'; } /* '' */
.icon-chamadoaberto:before { content: '\e857'; } /* '' */
.icon-chamadofechado2:before { content: '\e858'; } /* '' */
.icon-chamadoematendimento2:before { content: '\e859'; } /* '' */
.icon-atendimentotodos:before { content: '\e85a'; } /* '' */
.icon-copy:before { content: '\e85b'; } /* '' */
.icon-compartilhar:before { content: '\e85c'; } /* '' */
.icon-numbers:before { content: '\e85d'; } /* '' */
.icon-addaviso:before { content: '\e85e'; } /* '' */
.icon-megaphone:before { content: '\e85f'; } /* '' */
.icon-pesquisa:before { content: '\e860'; } /* '' */
.icon-magnifier:before { content: '\e861'; } /* '' */
.icon-review:before { content: '\e862'; } /* '' */
.icon-next:before { content: '\e863'; } /* '' */
.icon-in-love:before { content: '\e864'; } /* '' */
.icon-good-mood-emoticon:before { content: '\e865'; } /* '' */
.icon-sceptic:before { content: '\e866'; } /* '' */
.icon-smiling-circular-face:before { content: '\e867'; } /* '' */
.icon-big-smiley-face:before { content: '\e868'; } /* '' */
.icon-sad:before { content: '\e869'; } /* '' */
.icon-smiley:before { content: '\e86a'; } /* '' */
.icon-bar-chart:before { content: '\e86b'; } /* '' */
.icon-bar-chart2:before { content: '\e86c'; } /* '' */
.icon-danger1:before { content: '\e86d'; } /* '' */
.icon-danger:before { content: '\e86e'; } /* '' */
.icon-photo-camera1:before { content: '\e86f'; } /* '' */
.icon-photo-camera2:before { content: '\e870'; } /* '' */
.icon-photo-camera:before { content: '\e871'; } /* '' */
.icon-question-mark-on-a-circular-black-background:before { content: '\e872'; } /* '' */
.icon-search-1:before { content: '\e877'; } /* '' */
.icon-house-search:before { content: '\e878'; } /* '' */
.icon-searching-for-a-house:before { content: '\e879'; } /* '' */
.icon-house-searcher:before { content: '\e87a'; } /* '' */
.icon-star:before { content: '\e87d'; } /* '' */
.icon-starnotavalied:before { content: '\e87e'; } /* '' */
.icon-booking:before { content: '\e881'; } /* '' */
.icon-hotel:before { content: '\e882'; } /* '' */
.icon-hotel1:before { content: '\e883'; } /* '' */
.icon-funnel:before { content: '\e884'; } /* '' */
.icon-excel:before { content: '\e885'; } /* '' */
.icon-spreadsheet:before { content: '\e886'; } /* '' */
.icon-pdf1:before { content: '\e887'; } /* '' */
.icon-pdf2:before { content: '\e888'; } /* '' */
.icon-takeoff-the-plane:before { content: '\e88a'; } /* '' */
.icon-trocarplano:before { content: '\e88b'; } /* '' */
.icon-localization:before { content: '\e88c'; } /* '' */
.icon-nofilter:before { content: '\e88d'; } /* '' */
.icon-up-arrow:before { content: '\e88e'; } /* '' */
.icon-phone:before { content: '\e88f'; } /* '' */
.icon-whatsapp:before { content: '\e890'; } /* '' */
.icon-open-menu:before { content: '\e891'; } /* '' */
.icon-menunew:before { content: '\e892'; } /* '' */
.icon-menunew1:before { content: '\e893'; } /* '' */
.icon-open-menu1:before { content: '\e899'; } /* '' */
.icon-add1:before { content: '\e89a'; } /* '' */
.icon-add2-1:before { content: '\e89b'; } /* '' */
.icon-add4:before { content: '\e89c'; } /* '' */
.icon-add3:before { content: '\e89d'; } /* '' */
.icon-feitoweb:before { content: '\e89e'; } /* '' */
.icon-esperawev:before { content: '\e89f'; } /* '' */
.icon-prioridadeweb:before { content: '\e8a0'; } /* '' */
.icon-prioridadeweb2:before { content: '\e8a1'; } /* '' */
.icon-prioridadeweb3:before { content: '\e8a2'; } /* '' */
.icon-upload:before { content: '\e8a5'; } /* '' */
.icon-check-1:before { content: '\e8a6'; } /* '' */
.icon-pause-1:before { content: '\e8a7'; } /* '' */
.icon-play:before { content: '\e8a8'; } /* '' */
.icon-play-button-arrowhead:before { content: '\e8a9'; } /* '' */
.icon-stop-button-black-rounded-square:before { content: '\e8aa'; } /* '' */
.icon-stop-button:before { content: '\e8ab'; } /* '' */
.icon-send3:before { content: '\e8b5'; } /* '' */
.icon-send2:before { content: '\e8b6'; } /* '' */
.icon-logoutatendimento:before { content: '\e8b7'; } /* '' */
.icon-refresh2:before { content: '\e8b8'; } /* '' */
.icon-refresh-arrow:before { content: '\e8b9'; } /* '' */
.icon-calendar-(3):before { content: '\e8ba'; } /* '' */
.icon-computerlicense:before { content: '\e8bb'; } /* '' */
.icon-nocomputerlicense:before { content: '\e8bc'; } /* '' */
.icon-nocomputer:before { content: '\e8bd'; } /* '' */
.icon-no-chatting:before { content: '\e8be'; } /* '' */
.icon-chat1:before { content: '\e8bf'; } /* '' */
.icon-transferir:before { content: '\e8c0'; } /* '' */
.icon-audio-speaker-off:before { content: '\e8c1'; } /* '' */
.icon-audio-speaker-on:before { content: '\e8c2'; } /* '' */
.icon-listing-option:before { content: '\e8c3'; } /* '' */
.icon-list-on-window:before { content: '\e8c4'; } /* '' */
.icon-portal:before { content: '\e8d3'; } /* '' */
.icon-web-site:before { content: '\e8d4'; } /* '' */
.icon-rubbish-bin:before { content: '\e8f0'; } /* '' */
.icon-training:before { content: '\e8f6'; } /* '' */
.icon-report:before { content: '\e8f7'; } /* '' */
.icon-setup:before { content: '\e8f8'; } /* '' */
.icon-nextbold:before { content: '\e8fa'; } /* '' */
.icon-take-off:before { content: '\e8fc'; } /* '' */
.icon-help:before { content: '\e8fd'; } /* '' */
.icon-lifesaver:before { content: '\e8fe'; } /* '' */
.icon-sino:before { content: '\e904'; } /* '' */
.icon-smartphone:before { content: '\e928'; } /* '' */
.icon-no-mobile-phones-allowed:before { content: '\e929'; } /* '' */
.icon-no-phone4:before { content: '\e92a'; } /* '' */
.icon-global:before { content: '\e92b'; } /* '' */
.icon-phone123:before { content: '\e92c'; } /* '' */
.icon-icon_02:before { content: '\e96a'; } /* '' */
.icon-imagewpp:before { content: '\e9e3'; } /* '' */
.icon-video-camera:before { content: '\e9e4'; } /* '' */
.icon-documentwpp:before { content: '\e9e5'; } /* '' */
.icon-checkwpp:before { content: '\e9e6'; } /* '' */
.icon-double-tick-indicator:before { content: '\e9e7'; } /* '' */
.icon-checkwpp2:before { content: '\e9e8'; } /* '' */
.icon-clockteste3:before { content: '\e9e9'; } /* '' */
.icon-watch:before { content: '\e9eb'; } /* '' */
.icon-clockteste:before { content: '\e9ec'; } /* '' */
